import { PaymentMethodType } from '../enums/payment-method-type';
import { ResourceStatus } from '../enums/resource-status';
import { SearchCriteria } from "./search-criteria";

export class PaymentMethodSearchCriteria extends SearchCriteria {
    public Id: number;
    public MainBranchId:number;
    public OtherBranchId:number;
    public Name: string;
    public PaymentMethodType: PaymentMethodType;
    public Status:ResourceStatus;
    public UseLoyalityPoints : boolean;
   
}

