<div style="
    text-align: center;
    margin: 10px 5px;
    background-color: #fff;
    page-break-after: always;" *ngFor="let employeeId of item.TransEmployees; let e = index" [dir]="dirc"
  [ngStyle]="{'page-break-after': e != item.TransEmployees.length - 1 ? 'always' : ''}">
  <div style="">

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <div style="text-align: center;width: 100%;" *ngIf="AccountSetup?.ImagePath">
          <img [src]="AccountSetup?.ImagePath" style="width: 100px;text-align: center;" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <p class="hed" style="
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        color: #000;
        font-weight: 600;
        font-size: 16px; ">
          {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr : sharedService.BranchNameEn }} &nbsp; {{
          printLang }}
        </p>
      </div>
    </div>

    <hr />



    <table style="width: 100%" [dir]="dirc">

      <tr class="row">
        <td class="col-3 text-start">
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BillDate" | translate }} : {{ item.Date | date: "dd/MM/yyyy" }}
          </p>
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BillTime" | translate }} : {{ item.Date | date: "hh:mm a" }}
          </p>

          <p *ngIf="item.BookingDate" style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BookingDate" | translate }} : {{ item.BookingDate | date: "dd/MM/yy" }}
          </p>
          <p *ngIf="item.BookingDate" style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ "BookingTime" | translate }} : {{ item.BookingDate | date: "HH:mm" }}
          </p>

        </td>



        <td class="col-6 text-center">
          <!-- <img src="assets/img/g-logo.png" style="width: 60px;"> -->
          <!-- <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
            <img [src]="AccountSetup?.ImagePath" style="width: 60px" />
          </div>
          <p class="hed" style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px; ">
            {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr : sharedService.BranchNameEn }} &nbsp; {{
            printLang }}
          </p> -->

          <p class="hed" *ngIf="item.TransactionType == 2 ||
          item.TransactionType == 1 " style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px;
            ">
            {{'BillNumber' | translate }}&nbsp;-&nbsp;{{item.TransactionCodeText}}
          </p>

          <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
                      sharedService.CurrentBranch
                        .AllowUseNumberOfPrintsInBills &&
                      sharedService.CurrentBranch.AllowShowNumberOfPrintsInBills
                    ">
            <span>{{ "PrintNumber:" | translate }}</span>
            {{ (item.EmployeesNumberOfPrints ?? 0) + 1 }} .
          </p>

          <p style="font-size: 18px; font-weight: 600; margin: 5px 0px" *ngIf="
                      sharedService.CurrentBranch
                        .AllowShowTipsPrintBill &&
                        item.Tips > 0p
                    ">
            <span>{{ "Tips:" | translate }}</span>
            {{ item.Tips.toFixed(sharedService.GetFractionRoundCount) }} &nbsp; {{CurrencyName}}
          </p>

          <p class="hed" *ngIf="item.BookingId"
            style="margin: 5px 0  0 0; padding: 0;color: #430fd4; text-align: center; font-weight: 600; font-size: 16px;">
            {{'ForBookingPayment' | translate }}
          </p>
        </td>
        <td class="col-3 text-end">
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ item.Id }}
          </p>
        </td>
      </tr>
    </table>
    <div>

      <div class="row">
        <div class="col-lg-12 " style="text-align: center;">
          <h2 style=" color:#000; font-weight:900;">
            {{ ('PrintEmployees'|translate) }}
          </h2>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-12 " style="text-align: center;">
          <h4 style=" color:#000; font-weight:900;">
            {{ ('EmployeeName'|translate) + " - " + getEmployeeName(employeeId) }}
          </h4>
        </div>
      </div>

      <!-- [dir]="printLang  == 'ar' ? 'rtl' : 'ltr'" -->
      <table style="width: 100%; text-align: start" [dir]="dirc">
        <tr>
          <td style="
              font-family: initial;
              font-size: 20px;
              font-weight: 500;
              width: 160px;
              color: #000;
            ">
            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "ClientName" | translate }} :
              {{
              printLang == "ar"
              ? item.client?.NameAr
              : item.client?.NameEn
              }}
            </p>

            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "UserName" | translate }} :
              {{
              printLang == "ar"
              ? item.employee?.NameAr
              : item.employee?.NameEn
              }}
            </p>
          </td>
          <td style="text-align: end">
            <div style="text-align: end" *ngIf="AccountSetup?.QRImagePath">
              <img [src]="AccountSetup?.QRImagePath" alt="" style="width: 80px" />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div *ngIf="item.TrProductServices.length > 0">
        <table border="1" style="
            width: 100%;
            border-collapse: collapse;
            text-align: start !important;
            margin: 15px 0;
            border: 2px solid #000;
          " [dir]="dirc">
          <thead style="text-align: start !important" [dir]="dirc">
            <tr style="text-align: start !important" [dir]="dirc">
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Name" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Employee" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Quantity" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Price" | translate }}
              </th>
              <!-- <th style="text-align: start !important; color: #000; padding:5px;">{{ 'Commission' | translate }}
              </th> -->
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Total" | translate }}
              </th>
              <th *ngIf="sharedService?.CurrentBranch?.AllowTracingCommissionInCashSale == true &&
               this.hasNonNullEmployeeTransactionCommission(item)"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "Commission" | translate }}
              </th>

              <th *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "QueueNumber" | translate }}
              </th>

            </tr>
          </thead>
          <tbody *ngFor="  let TrProductService of item.TrProductServices; let i = index ">
            <tr style="text-align: start" *ngIf="TrProductService.EmployeeId === employeeId">
              <td style="color: #000; padding: 5px">
                {{
                TrProductService.service
                ? printLang == "ar"
                ? TrProductService.service.NameAr
                : TrProductService.service.NameEn
                : TrProductService.product
                ? printLang == "ar"
                ? TrProductService.product.NameAr
                : TrProductService.product.NameEn
                : TrProductService.Package
                ? printLang == "ar"
                ? TrProductService.Package.NameAr
                : TrProductService.Package.NameEn
                : TrProductService.OffersSetting
                ? printLang == "ar"
                ? TrProductService.OffersSetting.NameAr
                : TrProductService.OffersSetting.NameEn
                : ""
                }}
                <div *ngIf="AccountSetup?.AllowUseRooms == true">
                  <span *ngFor="
                      let room of TrProductService.ResourceManagements
                    ">
                    {{ room.ResourceName }} <br />
                    {{ "From" | translate }}:
                    {{ room.StartTime | date: "shortTime" }} ,
                    {{ "To" | translate }}:
                    {{ room.EndTime | date: "shortTime" }}
                  </span>
                </div>
              </td>
              <td style="color: #000; padding: 5px">


                {{ TrProductService.Employee ? (printLang == "ar" ?
                TrProductService.Employee?.NameAr :
                TrProductService.Employee?.NameEn ): ('NotFound'|translate)}}


                {{ TrProductService?.SecondEmployee ? " - " +
                (printLang == "ar" ?
                TrProductService.SecondEmployee?.NameAr :
                TrProductService.SecondEmployee?.NameEn) : "" }}
              </td>
              <td style="color: #000; padding: 5px">
                {{ TrProductService.Quantity }}
              </td>
              <td *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees"
                style="color: #000; padding: 5px">
                {{ TrProductService.OriginalPrice.toFixed(sharedService.GetFractionRoundCount) }}
              </td>
              <!-- <td style="color: #000; padding:5px;">
                {{TrProductService?.EmployeeTransactionCommission[0]?.CommissionValue }} </td> -->
              <td *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees"
                style="color: #000; padding: 5px">
                {{ TrProductService.Total.toFixed(sharedService.GetFractionRoundCount) }}
              </td>
              <td *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
               && TrProductService?.EmployeeTransactionCommission != null
               && TrProductService.EmployeeTransactionCommission?.CommissionValue > 0"
                style="color: #000; padding: 5px">

                <span *ngIf="TrProductService.EmployeeTransactionCommission?.OverTimeCommissionId != null">
                  {{ "ServiceFeeOvertime" | translate }}
                </span>
                <span>
                  {{
                  TrProductService.EmployeeTransactionCommission?.CommissionValue.toFixed(sharedService.GetFractionRoundCount)
                  }}
                </span>
              </td>

              <td *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="color: #000; padding: 5px">
                {{ TrProductService.ClientNumberInQueue }}
              </td>
            </tr>

            <tr style="text-align: start"
              *ngIf="TrProductService.EmployeeId === employeeId && TrProductService.IsEmployeeCommissionForClients">
              <td colspan="10" style=" font-family: initial; font-size: 13px; font-weight: 400;
              color: #000; padding: 5px;">
                {{ "CommissionAtTheRequestOfAClient" | translate }}
              </td>
            </tr>

            <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
                 && TrProductService?.EmployeeTransactionCommission != null
                 && TrProductService?.EmployeeTransactionCommission?.HrCommissionSettingDetailId == null
                 && TrProductService?.EmployeeTransactionCommission?.TargetCommissionValue != 0
                 && TrProductService.EmployeeId === employeeId">
              <td colspan="6">
                <div style="display: flex; justify-content: space-between">

                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "Target" | translate }} :

                    {{ TrProductService?.EmployeeTransactionCommission?.TargetCommissionValue }}
                    {{ TrProductService?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                    (sharedService.Lang == 'ar' ? (sharedService.AccountCurrency?.NameAr | translate) :
                    (sharedService.AccountCurrency?.NameEn | translate)) : ('Servicess' | translate) }}:


                  </p>

                </div>
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "TotalInvestigator" | translate }} :

                    {{ TrProductService?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                    ( TrProductService?.EmployeeTransactionCommission?.TotalAchievedServicePrice) :
                    ( TrProductService?.EmployeeTransactionCommission?.TotalInvoicesCount)}}
                  </p>
                  <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                      TrProductService?.EmployeeTransactionCommission?.IsTargetAccomplished == true">
                    {{ "TargetAchieved" | translate }}
                  </p>
                  <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                      TrProductService?.EmployeeTransactionCommission?.IsTargetAccomplished == false">
                    لم يتم تحقيق التارجت حتى الأن
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    الايام المتبقية لتحقيق التارجت :
                    {{ TrProductService?.EmployeeTransactionCommission?.RemainingTargetDays }}
                  </p>
                </div>

              </td>

            </tr>

            <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
            && TrProductService?.EmployeeTransactionCommission == null
            && TrProductService.EmployeeId === employeeId">

              <td colspan="6">
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "NoCommissionCalculated" | translate }}
                  </p>
                </div>
              </td>
            </tr>
            <tr *ngIf="
                TrProductService.TransactionsPackageServices &&
                TrProductService.TransactionsPackageServices.length != 0 &&
                TrProductService.ItemType == 2
              ">
              <td colspan="5">
                <table style="width: 100%">
                  <tbody *ngFor="
                      let packageservice of TrProductService.TransactionsPackageServices;
                      let i = index
                    ">
                    <tr *ngIf="packageservice.EmployeeId == employeeId">
                      <td>{{ "Package" | translate }}</td>
                      <td colspan="5">
                        <table border="1" style="width: 100%">
                          <tbody>
                            <tr>
                              <td>
                                <p style="font-size: 13px">
                                  {{
                                  printLang ==
                                  "ar"
                                  ? packageservice.Service?.NameAr
                                  : packageservice.Service?.NameEn
                                  }}
                                </p>
                              </td>
                              <td style="color: #000; padding: 5px">
                                {{
                                printLang =="ar"? packageservice.Employee?.NameAr
                                : packageservice.Employee?.NameEn}}
                              </td>
                              <td>
                                {{ packageservice.ServicePriceInPackage.toFixed(sharedService.GetFractionRoundCount)}}
                              </td>
                              <td>
                                {{
                                packageservice?.EmployeeTransactionCommission
                                ?.CommissionValue
                                }}
                              </td>
                              <td>
                                {{
                                packageservice.ServicePriceInPackageWithTax.toFixed(2)
                                }}
                              </td>
                              <td *ngIf="packageservice?.ClientNumberInQueue">
                                {{ packageservice?.ClientNumberInQueue }}
                              </td>
                            </tr>

                            <tr *ngIf="packageservice?.EmployeeTransactionCommission &&
                            AccountSetup?.AllowTracingCommissionInCashSale ==true
                              ">

                              <td colspan="6">
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    {{ "Commission" | translate }}
                                    {{
                                    packageservice
                                    ?.EmployeeTransactionCommission
                                    ?.CommissionValue
                                    }}
                                  </p>
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    المستهدف :
                                    {{
                                    packageservice
                                    ?.EmployeeTransactionCommission
                                    ?.TargetCommissionValue
                                    }}
                                  </p>
                                </div>
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    اجمالى المحقق :
                                    {{
                                    packageservice
                                    ?.EmployeeTransactionCommission
                                    ?.TotalAchievedServicePrice
                                    }}
                                  </p>

                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    " *ngIf="
                                      packageservice
                                        ?.EmployeeTransactionCommission
                                        ?.IsTargetAccomplished == true
                                    ">
                                    تم تحقيق التارجت
                                  </p>
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    " *ngIf="
                                      packageservice
                                        ?.EmployeeTransactionCommission
                                        ?.IsTargetAccomplished == false
                                    ">
                                    لم يتم تحقيق التارجت
                                  </p>
                                </div>
                                <div style="
                                    display: flex;
                                    justify-content: space-between;
                                  ">
                                  <p style="
                                      padding: 0px;
                                      margin: 0px;
                                      font-size: 12px;
                                    ">
                                    الايام المتبقية لتحقيق التارجت :
                                    {{
                                    packageservice
                                    ?.EmployeeTransactionCommission
                                    ?.RemainingTargetDays
                                    }}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style="
          text-align: start;
          font-size: 14px;
          font-weight: 500;
          color: #000;
        ">
        {{ item.Comment }}
      </p>
      <!-- <hr />
      <div></div>
      <div style="width: 100%; height: 1px; border: 1px solid #000; margin: 5px"></div>
      <p style="text-align: center; font-weight: 500; color: #000">
        {{ AccountSetup?.PrintReceiptAdvertText }}
      </p> -->


      <div style="display: flex; align-items: center; justify-content: center;">
        <span *ngIf="AccountSetup?.AllowUseClientsQueueSystem &&
          AccountSetup?.AllowShowClientsQueueNumberInPrintEmployees &&
          AccountSetup?.ClientsQueueSystemType == 1 &&
          item.ClientNumberInQueue && item.ClientNumberInQueue > 0" style="margin-right:30px;">

          <div style="text-align: center;">
            <span style="display: block; font-size: 25px; font-weight: 700;">{{ "ClientNumberInQueue" | translate
              }}</span>
          </div>
          <p style="text-align: center; font-weight: 700; color: #000;
font-family: initial;font-size: 60px; font-weight: 700;margin: 15; background-color: #373333  !important;
color: white;padding: 5px; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
            <span>{{ item?.ClientNumberInQueue }}</span>
          </p>
        </span>
      </div>

      <span *ngIf="sharedService.CurrentBranch.AllowChangeBillItemsEmployeesByQRCode">

        <div class="row">
          <div class="col-lg-12 " style="text-align: center;">
            <h3 style="color:#000; font-weight:900;">
              {{ ('ScanToChangeItemEmployeeToYourself'|translate )}}
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 " style="text-align: center;"
            *ngFor="let trItem of item.TrProductServices; let i = index ">

            <div class="row" *ngIf="trItem.EmployeeId == employeeId">
              <div class="col-lg-12 " style="text-align: center;">
                <h4 style="color:#000; font-weight:900;">
                  {{ 'ItemName'|translate }} :

                  {{
                  trItem.service
                  ? printLang == "ar"
                  ? trItem.service.NameAr
                  : trItem.service.NameEn
                  : trItem.product
                  ? printLang == "ar"
                  ? trItem.product.NameAr
                  : trItem.product.NameEn
                  : trItem.Package
                  ? printLang == "ar"
                  ? trItem.Package.NameAr
                  : trItem.Package.NameEn
                  : trItem.OffersSetting
                  ? printLang == "ar"
                  ? trItem.OffersSetting.NameAr
                  : trItem.OffersSetting.NameEn
                  : ""
                  }}
                </h4>
              </div>
            </div>

            <div style="display: flex; align-items: center; justify-content: center;"
              *ngIf="trItem.EmployeeId == employeeId">
              <div style="text-align: center;">
                <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                  [value]="BillInfoUrl+trItem.Id" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>
              </div>
            </div>


            <!-- Package -->
            <br>
            <div class="row"
              *ngIf="trItem.TransactionsPackageServices && trItem.TransactionsPackageServices.length > 0">
              <div class="col-lg-12 " style="text-align: center;"
                *ngFor="let trPackageItem of trItem.TransactionsPackageServices; let j = index ">

                <div class="row" *ngIf="trPackageItem.EmployeeId == employeeId">
                  <div class="col-lg-12 " style="text-align: center;">
                    <h4 style=" color:#000; font-weight:900;">
                      {{ 'ItemName'|translate }} :

                      {{
                      trPackageItem.Service
                      ? printLang == "ar"
                      ? trPackageItem.Service.NameAr
                      : trPackageItem.Service.NameEn
                      : ""
                      }}
                    </h4>
                  </div>
                </div>

                <div style="display: flex; align-items: center; justify-content: center;"
                  *ngIf="trPackageItem.EmployeeId == employeeId">
                  <div style="text-align: center;">
                    <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                      [value]="BillInfoForPackageUrl+trPackageItem.Id" alt="Demo QR Code"
                      cssClass="bshadow"></ngx-qrcode>
                  </div>
                </div>


              </div>
            </div>


          </div>
        </div>

      </span>
    </div>
  </div>
</div>
