import { Booking } from "../../../dashboard/bookings/models/booking";
import { FileType } from "../../enums/file-type";
import { Journal } from "../accounts/journal";
import { Transactions } from "./transactions";


export class Attachment {
    constructor() {
    }

    public Id: number;
    public TransactionId: number;
    public Transaction: Transactions;
    public JournalId : number;
    public Journal: Journal;
    public BookingId: number;
    public Booking: Booking;
    public SupplierId: number;
    public ClientMedicalHistoryId: number;
    public FileName: string;
    public FolderName: string;
    public FileType: FileType;
    public FilePath: string;
    public FileNotes: string;
}


