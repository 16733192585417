import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateSharedModule } from './translate-shared/translate-shared.module';
import { SzAutocompleteModule } from '../dashboard/shared/sz-autocomplete/sz-autocomplete.module';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PrintGymComponent } from './print-component/print-gym/print-gym.component';
import { LoadingComponent } from './loading/loading.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchComponent } from './search/search.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalAcceptOrRejectRequestPermissionComponent } from './modal-accept-or-reject-request-permission/modal-accept-or-reject-request-permission.component';
import { PrintBillComponent } from './print-component/print-bill/print-bill.component';
import { PrintItemsBookingComponent } from 'src/app/shared/print-component/print-items-booking/print-items-booking.component';
import { PrintBillAndEmployeesComponent } from './print-component/print-bill-and-employees/print-bill-and-employees.component';
import { BlankComponent } from './blank/blank.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgxPrintModule } from 'ngx-print';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PrintBookingBillComponent } from './print-component/print-booking-bill/print-booking-bill.component';
import { OptionComponent } from './option/option.component';
import { ChooseAccountsComponent } from './choose-accounts/choose-accounts.component';
import { ChooseCostCenterComponent } from './choose-cost-center/choose-cost-center.component';
import { PrintEmployeeComponent } from './print-component/print-employee/print-employee.component';
import { PrintEmployeeBookingComponent } from './print-component/print-employee-booking/print-employee-booking.component';
import { AddAccountComponent } from './add-account/add-account.component';
import { AddCostCenterComponent } from './add-cost-center/add-cost-center.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { MatIconModule } from '@angular/material/icon';
import { LightboxModule } from 'ngx-lightbox';
import { MatDialogModule } from '@angular/material/dialog';
import { PrintBillA4Component } from './print-component/print-contract/print-contract.component';
import { ModalSafeTransactionsComponent } from 'src/app/shared/modal-safe-transactions/modal-safe-transactions.component';
import { DateLangPipe } from '../pipes/date-lang.pipe';
import { TimeLangPipe } from '../pipes/time-lang.pipe';
import { PrintBill3Component } from './print-component/print-bill3/print-bill3.component';
import { ModalClientBalanceOperationsComponent } from './modal-client-balance-operations/modal-client-balance-operations.component';
import { TransactionPaymentTwoComponent } from './transaction-payment-two/transaction-payment-two.component';
import { ModalClientBalanceDetailsComponent } from './modal-client-balance-details/modal-client-balance-details.component';
import { PaginationModule } from './pagination/pagination.module';

import { ModalPayForPostpaidBillsWithCashSaleComponent } from './modal-pay-for-postpaid-bills-with-cash-sale/modal-pay-for-postpaid-bills-with-cash-sale.component';
import { ModalClientGiftCardDetailsComponent } from './modal-client-gift-card-details/modal-client-gift-card-details.component';
import { WeekDaysComponent } from './week-days/week-days.component';
import { EmployeeStatusComponent } from './employee-status/employee-status.component';
import { ModalAssignMembershipExercisesComponent } from './modal-assign-membership-exercises/modal-assign-membership-exercises.component';
import { ModalShowInfoToAllUsers } from './modal-show-info-to-all-users/modal-show-info-to-all-users.component';
import { ModalPictureComponent } from './modal-picture/modal-picture.component';
import { ClientAttendanceComponent } from './client-attendance/client-attendance.component';
import { ModalServiceConsumablesListComponent } from './modal-service-consumables-list/modal-service-consumables-list.component';
import { ModalShowServiceConsumablesInfo } from './modal-show-service-consumables-info/modal-show-service-consumables-info.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ApplicationPipesModule } from '../models/application-pipes-module/application-pipes.module';
import { ModalChangeGymMembershipClient } from './modal-change-gym-membership-client/modal-change-gym-membership-client.component';
import { ModalGymMembershipInfo } from './modal-gym-membership-info/modal-gym-membership-info.component';
import { ModalCancelGymMembership } from './modal-cancel-gym-membership/modal-cancel-gym-membership.component';
import { ModalShowIntroVideo } from './modal-show-intro-video/modal-show-intro-video.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { GroupTapComponent } from './components/group-tap/group-tap.component';
import { ServiceCategoriesComponent } from '../dashboard/bookings/services/service-categories/service-categories.component';
import { ProductcategoriesComponent } from '../dashboard/stock/productcategories/productcategories.component';
import { ModalmodalTermsConditions } from './modal-terms-and-conditions/modal-terms-and-conditions.component';
import { LoyalityPointsComponent } from './loyality-points/loyality-points.component';
import { ModalServiceEmployeeDetailsComponent } from './modal-service-employee-details/modal-service-employee-details.component';
import { ModalServiceEmployeeAddUpdateComponent } from './modal-service-employee-add-update/modal-service-employee-add-update.component';
import { ModalUploadAttachementsComponent } from './modal-upload-attachements/modal-upload-attachements.component';
import { TransactionHeaderComponent } from '../dashboard/shared/transaction-header/transaction-header.component';
import { TransactionPaymentComponent } from '../dashboard/shared/transaction-payment/transaction-payment.component';
import { ModalAddUpdatePettyCashComponent } from './modal-add-update-petty-cash/modal-add-update-petty-cash.component';
import { PrintBookingPerEmployeeComponent } from './print-component/print-booking-per-employee/print-booking-per-employee.component';
import { ArabicDatePipe } from '../pipes/arabic.pipe';
import { ModalGroupAddAndUpdate } from './modal-group-add-and-update/modal-group-add-and-update.component';
import { ModalViewGroupClients } from './modal-view-group-clients/modal-view-group-clients.component';
import { ModalChangeBillItemEmployeeToCurrentEmployee } from './modal-change-bill-item-employee-to-current-employee/modal-change-bill-item-employee-to-current-employee.component';
import { ModalBannedClientInfo } from './modal-banned-client-info/modal-banned-client-info.component';
import { VideoComponent } from './video/video.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalAddUpdateSupplierComponent } from './modal-add-update-supplier/modal-add-update-supplier.component';
import { CloseFiniancialPeriodComponent } from './close-finiancial-period/close-finiancial-period.component';
import { ModalClientProfileComponent } from './modal-client-profile/modal-client-profile.component';
import { ModalEmployeeItemInfoComponent } from './modal-employee-item-info/modal-employee-item-info.component';
import { NgxColorsModule } from 'ngx-colors';
import { PrintItemsComponent } from './print-component/print-items/print-items.component';
import { PrintBillAndItemsComponent } from './print-component/print-bill-and-items/print-bill-and-items.component';
import { ModalBuySureComponent } from './modal-buy-sure/modal-buy-sure.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { PaymentComponent } from 'src/app/shared/payment/payment.component';
import { ModalGymMembershipFreezInfo } from 'src/app/shared/modal-gym-membership-freez-info/modal-gym-membership-freez-info.component';
import { ModalGymMembershipFreezAddEdit } from 'src/app/shared/modal-gym-membership-freez-add-edit/modal-gym-membership-freez-add-edit.component';



const MODULES = [
  MatFormFieldModule,
  FlexLayoutModule,
  MatPaginatorModule,
  CarouselModule
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    TranslateSharedModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    SzAutocompleteModule,
    NgxPrintModule,
    NgxQRCodeModule,
    NgSelectModule,
    MatIconModule,
    LightboxModule,
    AngularEditorModule,
    MatDialogModule,
    PaginationModule,
    ApplicationPipesModule,
    NgxColorsModule,
    ...MODULES,
    YouTubePlayerModule
  ],
  declarations: [

    TransactionHeaderComponent,
    TransactionPaymentComponent,
    PaymentComponent,
    ConfirmModalComponent,
    PrintGymComponent,
    LoadingComponent,
    NotFoundComponent,
    ModalGroupAddAndUpdate,
    SearchComponent,
    ImportExportComponent,
    ModalDeleteComponent,
    ModalPictureComponent,
    ModalShowInfoToAllUsers,
    ModalServiceEmployeeDetailsComponent,
    ModalServiceEmployeeAddUpdateComponent,
    ModalGymMembershipFreezInfo,
    ModalGymMembershipFreezAddEdit,
    CloseFiniancialPeriodComponent,
    ModalViewGroupClients,
    ModalChangeBillItemEmployeeToCurrentEmployee,
    ModalBannedClientInfo,
    ModalClientProfileComponent,
    ModalAddUpdatePettyCashComponent,
    ModalAddUpdateSupplierComponent,
    ModalShowIntroVideo,
    ModalEmployeeItemInfoComponent,
    ModalUploadAttachementsComponent,
    ModalmodalTermsConditions,
    ModalAcceptOrRejectRequestPermissionComponent,
    ModalClientBalanceOperationsComponent,
    ModalServiceConsumablesListComponent,
    ModalAssignMembershipExercisesComponent,
    ModalPayForPostpaidBillsWithCashSaleComponent,
    ModalClientBalanceDetailsComponent,
    ModalShowServiceConsumablesInfo,
    ModalClientGiftCardDetailsComponent,
    ModalChangeGymMembershipClient,
    ModalGymMembershipInfo,
    ModalCancelGymMembership,
    PrintBillComponent,
    PrintItemsBookingComponent,
    PrintBillAndItemsComponent,
    PrintBillAndEmployeesComponent,
    AttachmentsComponent,
    BlankComponent,
    PageNotFoundComponent,
    PrintBookingBillComponent,
    OptionComponent,
    ChooseAccountsComponent,
    ChooseCostCenterComponent,
    AddCostCenterComponent,
    PrintEmployeeComponent,
    PrintItemsComponent,
    PrintEmployeeBookingComponent,
    AddAccountComponent,
    AttachmentsComponent,
    PrintBillA4Component,
    ModalSafeTransactionsComponent,
    DateLangPipe,
    TimeLangPipe,
    ArabicDatePipe,
    PrintBill3Component,
    TransactionPaymentTwoComponent,
    WeekDaysComponent,
    EmployeeStatusComponent,
    ClientAttendanceComponent,
    GroupTapComponent,
    ServiceCategoriesComponent,
    ProductcategoriesComponent,
    LoyalityPointsComponent,
    PrintBookingPerEmployeeComponent,
    VideoComponent,
    ModalBuySureComponent,
    ImageUploaderComponent
  ],
  exports: [
    NgbModule,
    TransactionHeaderComponent,
    TransactionPaymentComponent,
    PaymentComponent,
    SzAutocompleteModule,
    ConfirmModalComponent,
    PrintGymComponent,
    LoadingComponent,
    ModalViewGroupClients,
    NotFoundComponent,
    SearchComponent,
    ImportExportComponent,
    ModalDeleteComponent,
    ModalGroupAddAndUpdate,
    ModalPictureComponent,
    ModalShowInfoToAllUsers,
    ModalServiceEmployeeDetailsComponent,
    ModalServiceEmployeeAddUpdateComponent,
    ModalGymMembershipFreezInfo,
    ModalGymMembershipFreezAddEdit,
    ModalShowIntroVideo,
    CloseFiniancialPeriodComponent,
    ModalAddUpdateSupplierComponent,
    ModalUploadAttachementsComponent,
    ModalmodalTermsConditions,
    ModalAcceptOrRejectRequestPermissionComponent,
    ModalClientBalanceOperationsComponent,
    ModalChangeBillItemEmployeeToCurrentEmployee,
    ModalBannedClientInfo,
    ModalEmployeeItemInfoComponent,
    ModalClientProfileComponent,
    ModalServiceConsumablesListComponent,
    ModalAssignMembershipExercisesComponent,
    ModalPayForPostpaidBillsWithCashSaleComponent,
    ModalClientBalanceDetailsComponent,
    ModalShowServiceConsumablesInfo,
    ModalClientGiftCardDetailsComponent,
    ModalChangeGymMembershipClient,
    ModalGymMembershipInfo,
    ModalCancelGymMembership,
    PrintBillComponent,
    PrintItemsBookingComponent,
    PrintBillAndEmployeesComponent,
    PrintBillA4Component,
    ModalSafeTransactionsComponent,
    AttachmentsComponent,
    NgxPrintModule,
    PrintBillAndItemsComponent,
    ChooseCostCenterComponent,
    NgxQRCodeModule,
    PrintBookingBillComponent,
    OptionComponent,
    ChooseAccountsComponent,
    PrintEmployeeComponent,
    PrintItemsComponent,
    PrintEmployeeBookingComponent,
    AddAccountComponent,
    AddCostCenterComponent,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PrintBill3Component,
    DateLangPipe,
    TimeLangPipe,
    TransactionPaymentTwoComponent,
    WeekDaysComponent,
    EmployeeStatusComponent,
    ClientAttendanceComponent,
    ApplicationPipesModule,
    ServiceCategoriesComponent,
    ProductcategoriesComponent,
    AngularEditorModule,
    LoyalityPointsComponent,
    PrintBookingPerEmployeeComponent,
    ImageUploaderComponent,
    ...MODULES,
    VideoComponent,
    ModalBuySureComponent
  ],
})
export class SharedModule { }
