import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdminService } from '../dashboard/admin/service/admin.service';
import { SharedService } from '../services/other/shared.service';
import { ResponseState } from '../models/support/response-state';


@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private currentVersion = environment.version || '0.0.0';

  constructor(private http: HttpClient,  private adminService: AdminService, public sharedService: SharedService) {}

  public initVersionCheck(interval = 60 * 60 * 1000) {
    setInterval(() => this.checkVersion(), interval);
  }

  private checkVersion() {
    this.http.get('/assets/version.json?v=' + new Date().getTime()).subscribe((data: any) => {
      const latestVersion = data.version;
      console.log('Current Version:', this.currentVersion); // e.g., '0.2.0'
    console.log('Latest Version:', latestVersion); // e.g., '0.2.1'
      if (this.currentVersion !== latestVersion) {
        this.clearCacheAndReload();
      }
    });
  }

  private clearCacheAndReload() {
    console.log('New version detected. Clearing cache and refreshing...');
 
       // Clear all localStorage
      
        localStorage.removeItem('transType');
        localStorage.removeItem('stockTransDetaileds');
        localStorage.removeItem('invStockTransDetaileds');
    
        let jsonData = {
          BranchId: this.sharedService.AccountSetupId,
          FromInternalCode: true,
        };
    
        this.adminService.CurrentUserBranchValidation(jsonData)
          .subscribe((response: any) => {
            if (response.State == ResponseState.Success) {
    
              if (response.TokenObject) {
    
                localStorage.setItem(
                  'tokenObject',
                  JSON.stringify(response.TokenObject)
                );
    
                localStorage.setItem('token', response.TokenObject.Token);
    
                localStorage.setItem(
                  'tokenKey',
                  JSON.stringify(response.TokenObject)
                );
              }
    
              window.location.reload(); // Hard reload to clear the cache
    
           
            }
          });
      
      
  }
}
