<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-cloud-upload-alt"></i> &nbsp;
      <span *ngIf="!SupplierId">
        {{'AddSupplier' | translate}}
      </span>
      <span *ngIf="SupplierId">
        {{'UpdateSupplier' | translate}}
      </span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12" *ngIf="!LoadingGetSupplier; else loading">
        <form [formGroup]="GForm" (ngSubmit)="CreateData()">
          <div class="modal-body">
            <h5>{{ 'SupplierDetails' | translate }}</h5>
            <div class="formCard mt-3 ">
              <div class="row">
                <div class="form-group col-md-4">
                  <label>{{ 'Name' | translate }}</label>
                  <input type="text" class="form-control" id="Name" required formControlName="Name" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'Address' | translate }}</label>
                  <input type="text" class="form-control" id="Address" formControlName="Address" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'Phone' | translate }}</label>
                  <input type="text" class="form-control" id="PhoneNumber" formControlName="PhoneNumber" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'MobileNumber' | translate }}</label>
                  <input type="text" class="form-control" id="MobileNumber" formControlName="MobileNumber"
                    maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'OtherPhoneNumber' | translate }}</label>
                  <input type="text" class="form-control" id="OtherPhoneNumber" formControlName="OtherPhoneNumber"
                    maxlength="50">
                </div>
             
                <div class="form-group col-md-4">
                  <label for="Postcode">{{ 'Postcode' | translate }}</label>
                  <input type="text" class="form-control" id="Postcode" formControlName="Postcode" maxlength="50">
                </div>
                <div class="col-md-4">
                  <label>{{ 'TaxNumber' | translate }}</label>
                  <input formControlName="SupplierTaxNumber" class="form-control" >

                </div>
                <div class="col-md-2">
                  <div class="form-group row">
                    <label class="col-6">{{ 'Status' | translate }}
                    </label>
                    <div class="switch col-6 text-end">
                      <label>
                        <input type="checkbox" formControlName="Status" id="Status" checked="checked">
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div><br>
         
                <div class="form-group col-md-12">
                  <label for="Notes">{{'Notes'|translate}}</label>
                  <textarea rows="2" cols="50" class="form-control" id="Notes" formControlName="Notes"
                    maxlength="50"></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <button [disabled]="btnLoading" class="btn btn-danger" type="button"
                    (click)="openModalUploadAttachements()"> <i class="fas fa-cloud-upload-alt"></i>
                    &nbsp;{{'UploadAttachements' |
                    translate }}</button>
                </div>
            
         
          </div>
        </form>
      </div>
      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12 text-center py-4 my-4">
            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          </div>
        </ng-template>
      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button (click)="CreateData()" type="button" class="btn btn-danger" [disabled]="btnLoading || !GForm.valid">
      <span *ngIf="!btnLoading"> {{ 'Submit' | translate }}</span>
      <span *ngIf="btnLoading"> <i class="fas fa-circle-notch fa-spin"></i> {{ 'Loading' | translate
        }}</span>
    </button>

    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{'Close' | translate}}</button>

  </div>
</div>
