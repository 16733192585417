


export const environment ={
    production: true,
    RootDomain: "glamera.com",
    ApiUrl: "https://api-stage.glamera.com/api/",
    NewApiUrl: "https://api-stage.glamera.com/api/",
    ApiUrlV2: "https://api-stage.glamera.com/api/v2/",
    UploadUrl: "https://api-stage.glamera.com/api/Upload/",
    SurePayUrl: 'https://localhost/Api/Merchant/v1/',
    OldApiUrlFromGlamatekWeb: "https:/api-stage.glamera.com/",
    ReportDevUrl: "https://reports.glamera.com/",
    OnlineReportDevUrl: "https://reports.glamera.com/",
    BillingPortal: "https://subscription-stage.glamera.com/",
    BioTimeSignalRUrl: '',
    version: '0.0.0-staging',
  }

//     {
//       production: true,
//       ApiUrl: "http://localhost:5000/api/",
//       ApiUrlV2: "http://localhost:5000/api/v2/",
//       UploadUrl: "http://localhost:5000/api/Upload/",
//       OldApiUrlFromGlamatekWeb: "https://api.glamour-plan.com/",
//       ReportDevUrl: "https://reports.glamera.com/",
//       OnlineReportDevUrl: "https://reports.glamera.com/",
//     } : willPublishOn == (PublishOn.Test + "") ?

//       {
//         production: true,
//         ApiUrl: "https://api-dev.glamera.com/api/",
//         ApiUrlV2: "https://api-dev.glamera.com/api/v2/",
//         UploadUrl: "https://api-dev.glamera.com/api/Upload/",
//         OldApiUrlFromGlamatekWeb: "https://api-dev.glamera.com/",
//         ReportDevUrl: "https://reports-dev.glamera.com/",
//         OnlineReportDevUrl: "https://reports-dev.glamera.com/",
//       } : null;

