import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from '../../dashboard/accounts/services/cost-center.service';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { CostCenter } from '../../models/classes/accounts/cost-center';
import { AccountSetupSearchCriteria } from '../../models/search-criterias/account-setup-search-criteria';
import { ResponseState } from '../../models/support/response-state';
import { ClientService } from '../../services/client/client.service';
import { BaseServiceSZ } from '../../services/other/baseService';
import { SharedService } from '../../services/other/shared.service';
import { Transaction1Service } from '../../services/transaction/transaction.service';

@Component({
  selector: 'app-add-cost-center',
  templateUrl: './add-cost-center.component.html',
  styleUrls: ['./add-cost-center.component.scss']
})
export class AddCostCenterComponent implements OnInit {

  public GForm: UntypedFormGroup;
  public IsSubmit: boolean;
  public AccountSetupId: number;


  @Input() isNewAccount: boolean;
  @Input() infoDataItem: CostCenter;
  @Input() levelNum: number;
  freeText: any;


  constructor(private toastr: ToastrService,
    private clientService: ClientService,
    public sharedService: SharedService,
    private _BaseService: BaseServiceSZ,
    private fb: UntypedFormBuilder,
    private transactionService: Transaction1Service,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    public translateservice: TranslateService,
    public CostCenterService: CostCenterService,
    private adminService: AdminService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.GFormData();


    this.IsSubmit = false;



    if (this.isNewAccount == true) {
      //this.AccountSetupId = this.sharedService.AccountSetupId;

      this.GForm.get('IsChild').patchValue(true);
      this.GForm.get('LevelNumber').patchValue(this.levelNum);
      this.GForm.get('ParentId').patchValue(this.infoDataItem == undefined ? null :  this.infoDataItem.Id);
      this.GForm.get('IsActive').patchValue(true);
      this.GForm.get('ParentNumber').patchValue(this.infoDataItem == undefined ? null : this.infoDataItem.CostCenterNo);

      this.pNum = this.infoDataItem == undefined ? null : this.infoDataItem.CostCenterNo;
      this.pId = this.infoDataItem == undefined ? null : this.infoDataItem.Id ;
      this.GenerateNumber();
    }
    else  {
     this.AccountSetupId = this.infoDataItem.IsChild == false ? null : this.infoDataItem.AccountSetupId;
      this.GForm.patchValue(this.infoDataItem);
    }


    this.getAccountSetups();
    if (this.GForm.value.Id == 0 || this.GForm.value.Id == null) {

    }




  }


  sendMessage() {

    this.sharedService.setData('test');

    // let test = 'rtest one'
    // this.sharedService.sendMessage(test);
  }


  get IsActive() {
    return this.GForm.get('IsActive');
  }
  get IsChild() {
    return this.GForm.get('IsChild');
  }


  GFormData() {
    this.GForm = this.fb.group({
      Id: 0,
      CostCenterNo: ['', Validators.required],

      NameAr: ['', Validators.required],
      NameEn: ['', Validators.required],
      DescriptionAr: '',
      DescriptionEn: '',
      LevelNumber: 1,
      ParentId: null,
      AccountSetupId: null,
      IsChild: false,

      IsActive: true,

      ParentNumber: '',
      Code: ''
    })

  }
  ChangeIsActive(e) {
    if (e.target.checked) {
      this.GForm.controls.IsActive.setValue(true);
    } else {
      this.GForm.controls.IsActive.setValue(false);
    }
  }


  CreateData() {
    if (!this.IsSubmit) {
      this.IsSubmit = true;
      if (this.GForm.value.Id == 0 || this.GForm.value.Id == null) {
        this.Create();
      }
      else {
        this.Update();
      }
    }
  }
  @Output() LoadData = new EventEmitter();


  Create() {
    this.GForm.get('IsChild').setValue(Number(this.GForm.get('IsChild').value))

    if(this.GForm.value.ParentNumber == null)
    {
      this.GForm.get('CostCenterNo').setValue(this.GForm.value.CostCenterNo);
      this.GForm.get('LevelNumber').setValue(1);
    }
    else
    {
      this.GForm.get('CostCenterNo').setValue(this.GForm.value.ParentNumber + this.GForm.value.CostCenterNo);
    }


    this.CostCenterService.CreateCostCenter(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);

        this.IsSubmit = false;
        this.GForm.reset();
        this.GFormData();
        // this.sharedService.sendMessage(response);
        this.LoadData.emit('get');


        // this.SetClientData.emit(this.Clients);

      }
      else {
        this.IsSubmit = false;
        this.sharedService.ToastrError(response.Message);

      }
    });
  }
  Update() {
    this.GForm.get('IsChild').setValue(Number(this.GForm.get('IsChild').value))


    this.CostCenterService.UpdateCostCenter(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);

        this.GForm.reset();
        this.GFormData();
        this.sharedService.HideModal();
        this.IsSubmit = false;
        this.LoadData.emit('get');
        // this.GetData();
      }
      else {

        this.sharedService.ToastrError(response.Message);
        this.IsSubmit = false;

      }
    });
  }

  changeBranch(e) {
    this.AccountSetupId = e.Id;

  }


  pNum: any; pId: number;
  GenerateNumber() {
    this.CostCenterService.GenerateNumber(
      { "parentNumber": this.pNum, "parentId": this.pId }
    ).subscribe((response: any) => {
      if (response) {
        this.GForm.get('CostCenterNo').patchValue(response.Result);
      }
    })
  }


  branches; totalCount;
  getAccountSetups() {

    let accountSetupSearchCriteria = new AccountSetupSearchCriteria();
    accountSetupSearchCriteria.CompanyId = this.sharedService.CompanyId;
    accountSetupSearchCriteria.PagingEnabled = false;
    accountSetupSearchCriteria.FreeText = this.freeText;
    this.adminService.getAccountSetups(accountSetupSearchCriteria).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.branches = response.AccountSetups;
      }
    });
  }
  searchBranch(e) {
    this.freeText = e.term;
    this.getAccountSetups();

  }


}
