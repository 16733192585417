<div class="modal-header">
  <h4 class="modal-title">{{ "AccountsTree" | translate }}</h4>



  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="GForm">
        <div class="cardTabs">
          <div class="tabsContent">
            <div class="formCard">
              <div class="row">
                <div class="col-12">
                  <!-- <h5>{{ "AccountsTree" | translate }}</h5> -->
                </div>
               
                <div class="col-md-6">
                  <label>{{ "AccountNo" | translate }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="{{ 'AccountNo' | translate }}"
                      formControlName="AccountNo" autocomplete="off" required maxlength="50" />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">{{
                        GForm.get("ParentNumber").value
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ "AccountType" | translate }}</label>
                  <ng-select [items]="AccountTypes" bindValue="Id" (open)="GetAccountTypes()" [bindLabel]="
                      translateservice.currentLang == 'ar' ? 'NameAr' : 'NameEn'
                    " placeholder="{{ 'AccountType' | translate }}" formControlName="AccountTypeId" required
                    [readonly]="GForm.get('ParentId').value != null" class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-6">
                  <!--  -->
                  <label>{{ "NameAr" | translate }}</label>
                  <input type="text" class="form-control" placeholder="{{ 'NameAr' | translate }}"
                    formControlName="NameAr" required name="NameEn" maxlength="50" />
                </div>
                <div class="form-group col-md-6">
                  <label>{{ "NameEn" | translate }}</label>
                  <input type="text" class="form-control" placeholder="{{ 'NameEn' | translate }}"
                    formControlName="NameEn" required name="NameEn" maxlength="50" />
                </div>
                <div class="form-group col-md-6">
                  <label>{{ "ArabicAccountDescription" | translate }}</label>
                  <textarea class="form-control" formControlName="DescriptionAr" rows="3"
                    placeholder="{{ 'ArabicAccountDescription' | translate }}">
                  </textarea>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ "EnglishAccountDescription" | translate }}</label>
                  <textarea class="form-control" formControlName="DescriptionEn" rows="3"
                    placeholder="{{ 'EnglishAccountDescription' | translate }}">
                  </textarea>
                </div>
                <div class="form-group col-md-2">
                  <label>{{ "IsChild" | translate }} </label>
                  <div class="switch col-3 text-end">
                    <label>
                      <input type="checkbox" [checked]="IsChild.value == 1 ? true : false" class="form-check-input"
                        (change)="ChangeIsDebit($event)" formControlName="IsChild" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
               
                <div class="form-group col-md-5" *ngIf="GForm.get('IsChild').value == 1">
                  <label>{{ "Branch" | translate }}</label>
                  <ng-select [items]="branches" bindValue="Id" (change)="changeBranch($event)" (open)="getAccountSetups()"
                    [bindLabel]="sharedService.Lang == 'ar' ? 'Name' : 'Name'" placeholder="{{ 'Branch' | translate }}"
                    (search)="searchBranch($event)" formControlName="AccountSetupId" name="AccountSetupId" class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-12">
                  <label>{{ "CostCenters" | translate }}</label>
                  
                  <!-- Collapsible section with border -->
                  <div class="form-group col-md-5" *ngIf="GForm.get('IsChild').value == 1" class="cost-centers-container" [ngClass]="{ 'collapsed': !isCollapsed, 'expanded': isCollapsed }">
                    <div class="cost-centers-header" (click)="toggleCostCenters()" [ngClass]="{ 'active': isCollapsed }">
                      <i class="fa" [ngClass]="{ 'fa-chevron-down': isCollapsed, 'fa-chevron-up': !isCollapsed }"></i>
                      {{ (isCollapsed ? 'ShowCostCenters' : 'HideCostCenters') | translate }}
                    </div>
                
                    <!-- Inside Cost Centers Section -->
                    <div formArrayName="CostCenters" class="cost-center-rows" *ngIf="!isCollapsed">
                    
                      <div *ngFor="let item of CostCenters.controls; let i = index" [formGroupName]="i" class="cost-center-row">
                        <div class="input-group mb-3">
                          <button class="btn-primary btn-sm active" type="button" id="button-addon1" (click)="
                      openModalAccounts(i)">
                    <i class="fa fa-plus"></i> {{ 'CostCenter' | translate }}
                  </button>
                  <input type="text" class="form-control" placeholder="" aria-label="CostCenter"
                    aria-describedby="button-addon1" readonly formControlName="CostCenterName" name="CostCenterName" />
                          
                          <input type="number" class="form-control rate-input" formControlName="CostCenterPercentage" 
                                 placeholder="{{ 'Rate %' | translate }}" min="0" max="100" step="0.01" />
                          
                          <button  class="btn btn-danger btn-sm remove-btn" (click)="removeCostCenter(i)">
                            <i class="fa fa-trash"></i> {{ 'Remove' | translate }}
                          </button>
                        </div>
                      </div>
                
                      <!-- Add New Row -->
                      <div class="add-new-row" (click)="addCostCenter()">
                        <i class="fa fa-plus"></i> {{ 'AddCostCenter' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <!-- <div class="form-group col-md-5" *ngIf="GForm.get('IsChild').value == 1">
                  <button class="btn-primary btn-sm active" type="button" id="button-addon1" (click)="
                      openModalAccounts(GForm.get('AccountSetupId').value)
                    ">
                    <i class="fa fa-plus"></i> {{ 'CostCenter' | translate }}
                  </button>
                  <input type="text" class="form-control" placeholder="" aria-label="Example text with button addon"
                    aria-describedby="button-addon1" readonly formControlName="CostCenterName" name="CostCenterName" />
                </div> -->
              </div>
              <div class="row">
                <div class="form-group col-md-5">
                  <label>{{ "AccountGroup" | translate }}</label>
                  <ng-select [items]="AccountGroup" bindValue="Id" (change)="CheckData($event)" [bindLabel]="
                      sharedService.Lang == 'ar' ? 'NameAr' : 'NameEn'
                    " placeholder="{{ 'AccountType' | translate }}" formControlName="AccountGroupId"
                    name="AccountGroupId" class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-3" *ngIf="
                    GForm.get('IsChild').value == 1 && accountGroupCode == 3
                  ">
                  <label>{{ "ClientGroup" | translate }} </label>
                  <div class="switch col-3 text-end">
                    <label>
                      <input type="checkbox" [checked]="IsClientGroup == true ? true : false" class="form-check-input"
                        (change)="ChangeIsClientGroup($event)" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-3" *ngIf="
                    GForm.get('IsChild').value == 1 &&
                    accountGroupCode == 3 &&
                    IsClientGroup == true
                  ">
                  <label>{{ "ChooseGroup" | translate }}</label>
                  <ng-select placeholder="{{ 'ChooseGroup' | translate }}" [multiple]="false" [items]="ClientGroups"
                  formControlName="ClientGroupId" name="ClientGroupId" class="form-group" [bindLabel]="sharedService.Lang == 'ar' ? 'NameAr' : 'NameEn'" bindValue="Id" (change)="ChooseGroup($event)">
                  </ng-select>
                </div>

                <div class="form-group col-md-4"
                  *ngIf=" GForm.get('IsChild').value == 1 && accountGroupCode == 3 && IsClientGroup == false">
                  <label>{{ "Clients" | translate }}</label>
                  <ng-select [items]="Clients" bindValue="Id" [searchFn]="customSearchFn" bindLabel="Name" placeholder="{{ 'Clients' | translate }}"
                    formControlName="ClientId" name="ClientId" class="form-group" (open)="getClient(null)"
                    (search)="onClientSearch($event)">
                  </ng-select>
                </div>
                <div class="form-group col-md-4" *ngIf=" GForm.get('IsChild').value == 1 && accountGroupCode == 4 ">
                  <label cfor="EmployeeId">{{ 'Employees' | translate }}</label>
                  <ng-select placeholder="  {{ 'Employee' | translate }}" [multiple]="false"
                    [items]="Employees" (search)="onSearchEmp($event)" (clear)="clearSearchEmp()"
                    [bindLabel]="translateservice.currentLang=='ar'? 'NameAr':'NameEn'"
                    (open)="GetEmployee(null)" bindValue="Id" formControlName="EmployeeId">
                  </ng-select>
           
                </div>
                <div class="form-group col-md-5" *ngIf=" GForm.get('IsChild').value == 1 && accountGroupCode == 2 ">
                  <label>{{ "Suppliers" | translate }}</label>
                  <ng-select [items]="Suppliers" bindValue="Id" (open)="getSupplier(null)"
                    (search)="onSupplierSearch($event)" bindLabel="Name" placeholder="{{ 'Suppliers' | translate }}"
                    formControlName="SupplierId" name="SupplierId" class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-5" *ngIf=" GForm.get('IsChild').value == 1 && accountGroupCode == 20">
                  <label>{{ "Distributors" | translate }}</label>
                  <ng-select [items]="Distributors" bindValue="Id" bindLabel="Name"
                    placeholder="{{ 'Distributors' | translate }}" (open)="getDistributors()"
                    formControlName="DistributorId" name="DistributorId" class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-4" *ngIf="
                    GForm.get('IsChild').value == 1 && accountGroupCode == 6
                  ">
                  <label>{{ "Cashiers" | translate }}</label>
                  <ng-select [items]="Cashiers" bindValue="Id" bindLabel="NameAr" (open)="GetCashier(null)"
                    placeholder="{{ 'Cashiers' | translate }}" formControlName="CashierId" name="CashierId"
                    class="form-group">
                  </ng-select>
                </div>
                <div class="form-group col-md-2">
                  <label>{{ "IsActive" | translate }} </label>
                  <div class="switch col-2 text-end">
                    <label>
                      <input type="checkbox" [checked]="IsActive.value == 1 ? true : false" class="form-check-input"
                        (change)="ChangeIsActive($event)" formControlName="IsActive" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-2" *ngIf="GForm.get('IsChild').value == 1">
                  <label>{{ "ApplyTax" | translate }} </label>
                  <div class="switch col-3 text-end">
                    <label>
                      <input type="checkbox" [checked]="ApplyTax.value == 1 ? true : false" class="form-check-input"
                        (change)="ChangeApplyTax($event)" formControlName="ApplyTax" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-3" *ngIf="
                    GForm.get('ApplyTax').value == 1 &&
                    GForm.get('IsChild').value == 1
                  ">
                  <label>{{ "TaxValue" | translate }} </label>
                  <input type="number" class="form-control" placeholder="{{ 'TaxValue' | translate }}"
                    formControlName="TaxValue" name="TaxValue" maxlength="5" />
                </div>
                <div *ngIf="GForm.get('IsChild').value == 1" hidden class="form-group col-md-3">
                  <label>{{ "IsDebit" | translate }} </label>
                  <div class="switch col-3 text-end">
                    <label>
                      <input type="checkbox" formControlName="IsDebit" name="IsDebit" class="form-check-input" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="formCard mt-3">
          <div class="row">
            <div class="col-md-9"></div>
            <div class="col-md-3">
              <button class="btn btn-success btn-block" (click)="CreateData()" [disabled]="!GForm.valid">
                {{ "Submit" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-modal-action" (click)="activeModal.close('Close click')">
    {{ "Close" | translate }}
  </button>
</div>
