import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Attachment } from 'src/app/models/classes/transactions/attachment';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-modal-upload-attachements',
  templateUrl: './modal-upload-attachements.component.html',
  styleUrls: ['./modal-upload-attachements.component.scss'],
  providers: [DatePipe],
})
export class ModalUploadAttachementsComponent implements OnInit {

  @Input() GiftCardInformationId: number;
  @Input() GiftCardCode: string;
  @Output() CloseModal = new EventEmitter<Attachment[]>();
  @ViewChild('attachmentsComponent', { static: false })
  attachmentsComponent: AttachmentsComponent;
  public attachmentsFiles: Attachment[] = [];
  Attachments: UntypedFormArray;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private ngbModal: NgbModal,
    public toaster: ToastrService
  ) { }

  ngOnInit(): void {

  }

  onAttachmentsComponentInitialized(
    attachmentsComponent: AttachmentsComponent
  ) {
    this.attachmentsComponent = attachmentsComponent;

    if (this.attachmentsFiles && this.attachmentsFiles.length > 0) {
      this.attachmentsComponent.update(
        this.attachmentsFiles.map(
          ({
            Id,
            BookingId,
            TransactionId,
            JournalId,

            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          }) => ({
            Id,
            BookingId,
            TransactionId,
            JournalId,
            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          })
        )
      );
    }
  }


  divName;
  printPage() {
    this.divName = 'printClientGiftCardInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.attachmentsFiles = this.attachmentsComponent.attachmentsFiles;
  }

  closeModal() {
    this.attachmentsFiles = this.attachmentsComponent.attachmentsFiles;
    this.CloseModal.emit(this.attachmentsFiles);
    this.activeModal.close('Close click');
  }

}
