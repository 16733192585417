import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { ToastrService } from 'ngx-toastr';
import { GetPackageGroupsModel } from 'src/app/models/classes/transactions/get-package-groups-model';
import { PackageGroupDTO } from 'src/app/models/classes/transactions/package-group-dto';
import { PackageGroupServiceDTO } from 'src/app/models/classes/transactions/package-group-service-dto';

@Component({
  selector: 'app-modal-handle-package-groups',
  templateUrl: './modal-handle-package-groups.component.html',
  styleUrls: ['./modal-handle-package-groups.component.scss']
})
export class ModalHandlePackageGroupsComponent implements OnInit {

  @Input() PackageId: number;
  @Input() PackageName: string;
  @Output() public DoneSave = new EventEmitter<PackageGroupServiceDTO[]>();

  public PackageGroups: PackageGroupDTO[] = [];
  public LoadingGetPackageGroups: boolean = false;


  public selectedTab: number = 1;



  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.GetPackageGroups();
  }

  CheckIfCanAdd(packageGroupIndex: number, packageGroupServiceIndex: number) {

    let packageGroup = this.PackageGroups[packageGroupIndex];

    if (!packageGroup.AllowServicesDuplication) {

      let packageGroupService = this.PackageGroups[packageGroupIndex].
        PackageGroupServices[packageGroupServiceIndex];

      return !packageGroupService.ChoosenQuantity;

    }
    return true;
  }

  AddQuantity(packageGroupIndex: number, packageGroupServiceIndex: number) {
    debugger
    let packageGroupService = this.PackageGroups[packageGroupIndex].
      PackageGroupServices[packageGroupServiceIndex];

    packageGroupService.ChoosenQuantity = !packageGroupService.ChoosenQuantity ? 0 :
      packageGroupService.ChoosenQuantity;

    packageGroupService.ChoosenQuantity =
      packageGroupService.ChoosenQuantity + 1;

    this.CheckIfDoneChooseAllAllowedNumberOfServices(packageGroupIndex);
  }
  RemoveQuantity(packageGroupIndex: number, packageGroupServiceIndex: number) {

    let packageGroupService = this.PackageGroups[packageGroupIndex].
      PackageGroupServices[packageGroupServiceIndex];

    packageGroupService.ChoosenQuantity = !packageGroupService.ChoosenQuantity ? 0 :
      packageGroupService.ChoosenQuantity;

    packageGroupService.ChoosenQuantity =
      packageGroupService.ChoosenQuantity - 1;

    this.CheckIfDoneChooseAllAllowedNumberOfServices(packageGroupIndex);
  }

  GetPackageGroups() {

    this.PackageGroups = null;
    this.LoadingGetPackageGroups = true;
    let criteria = new GetPackageGroupsModel();
    criteria.PackageId = this.PackageId;

    this._transactionsSecondService
      .GetPackageGroups(criteria)
      .subscribe((response: ExecutionResponse<PackageGroupDTO[]>) => {

        if (response.State == ResponseState.Success) {
          this.PackageGroups = response.Result;
          this.tabClick(0);
        } else {
          this.sharedService.ToastrError(response.Message);
        }
        this.LoadingGetPackageGroups = false;
      });
  }

  CheckIfDoneChooseAllAllowedNumberOfServices(packageGroupIndex: number): boolean {

    let packageGroup = this.PackageGroups[packageGroupIndex];
    let packageGroupServices = this.PackageGroups[packageGroupIndex].PackageGroupServices;

    let allChoosenQuantity: number = packageGroupServices
      .reduce(function (a, b) {
        return a + (b.ChoosenQuantity ?? 0);
      }, 0);

    if (allChoosenQuantity >= packageGroup.AllowedNumberOfServices) {
      packageGroup.DoneChooseAllAllowedNumberOfServices = true;
      return true;
    } else {
      packageGroup.DoneChooseAllAllowedNumberOfServices = false;
      return false;
    }
  }

  CheckIfAllGroupsDoneChooseAllAllowedNumberOfServices(): boolean {

    let packageGroups = this.PackageGroups;
    return this.PackageGroups && this.PackageGroups.length > 0 &&
      packageGroups.every(pg => pg.DoneChooseAllAllowedNumberOfServices);
  }


  divName
  printPage() {

    this.divName = 'printClientProfile';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  tabClick(value) {
    this.selectedTab = value;
  }

  SaveAndContinue() {
    debugger
    if (this.CheckIfAllGroupsDoneChooseAllAllowedNumberOfServices()) {

      let willSentPackageGroupServices: PackageGroupServiceDTO[] = [];

      this.PackageGroups.forEach(packageGroup => {

        packageGroup.PackageGroupServices =
          packageGroup.PackageGroupServices.filter(gs => gs.ChoosenQuantity > 0);

        if (packageGroup.PackageGroupServices) {

          packageGroup.ColorCode = this.sharedService.randomColor(0);

          packageGroup.PackageGroupServices.forEach(packageGroupService => {
            for (let index = 0; index < packageGroupService.ChoosenQuantity; index++) {

              packageGroupService.GroupName = packageGroup.Name;
              packageGroupService.GroupColor = packageGroup.ColorCode;

              willSentPackageGroupServices.push(packageGroupService);
            }
          });
        }
      });
      debugger
      this.DoneSave.emit(willSentPackageGroupServices);
      this.activeModal.close('Close click');
    }
  }

}
