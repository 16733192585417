<div class=" print-wrap">
  <div class="" style="width: 380; padding: 10px; margin: 0px auto">
    <div *ngIf="item">
      <div id="print" class="printBox" style="background-color: #fff">


        <!--  Bill -->
        <div style="page-break-after: always;text-align: start;direction: rtl;
            width: 100%; margin: 0;background-color: #fff;" [dir]="dirc">
          <table style="width: 100%" [dir]="dirc">
            <tr>
              <td style="width: 25%">
                <p style="
                    font-family: initial;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 700;
                    color: #000;
                  ">
                  {{ "BillDate" | translate }} {{ item.Date | date: "dd/MM/yyyy" }}
                </p>
                <p style="
                    font-family: initial;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 700;
                    color: #000;
                  ">
                  {{ "BillTime" | translate }} {{ item.Date | date: "hh:mm a" }}
                </p>

                <p *ngIf="item.BookingDate" style="
                font-family: initial;
                font-size: 18px;
                margin: 0;
                font-weight: 700;
                color: #000;
              ">
                  {{ "BookingDate" | translate }} {{ item.BookingDate | date: "dd/MM/yyyy" }}
                </p>
                <p *ngIf="item.BookingDate" style="
                font-family: initial;
                font-size: 18px;
                margin: 0;
                font-weight: 700;
                color: #000;
              ">
                  {{ "BookingTime" | translate }} {{ item.BookingDate | date: "HH:mm" }}
                </p>

              </td>
              <td style="width: 50%">
                <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
                  <img [src]="AccountSetup?.ImagePath" style="width: 100px" />
                </div>
                <p class="hed" style="
                    margin: 5px 0 0 0;
                    padding: 0;
                    text-align: center;
                    color: #000;
                    font-weight: 700;
                    font-size: 18px;
                  ">
                  {{ sharedService.BranchNameAr }}
                </p>
                <p *ngIf="item.TransactionType == transactionType.CashSale" style="
                    margin: 5px 0 0 0;
                    padding: 0;
                    text-align: center;
                    color: #000;
                    font-weight: 700;
                    font-size: 18px;
                  ">
                  {{
                  AccountSetup?.SalesInvoiceHeaderTextAR
                  ? (sharedService.Lang == "ar" ? AccountSetup?.SalesInvoiceHeaderTextAR :
                  AccountSetup?.SalesInvoiceHeaderTextEN )
                  : ('SimpleTaxInvoice' | translate )
                  }}
                </p>

                <p *ngIf="item.TransactionType == transactionType.ClientDownPayment" style="
                    margin: 5px 0 0 0;
                    padding: 0;
                    text-align: center;
                    color: #000;
                    font-weight: 700;
                    font-size: 18px;
                  ">
                  {{
                  AccountSetup?.BookingDownPaymentHeaderTextAR
                  ? (sharedService.Lang == "ar" ? AccountSetup?.BookingDownPaymentHeaderTextAR :
                  AccountSetup?.BookingDownPaymentHeaderTextEN )
                  : ('SimpleTaxInvoice' | translate )
                  }}
                </p>

                <p *ngIf="item.BookingId" style=" margin: 5px 0 0 0;
                    padding: 0;
                    color: #430fd4;
                    text-align: center;
                    font-weight: 700;
                    font-size: 16px;">
                  {{'ForBookingPayment' | translate }} ( {{'Number' | translate }}
                  &nbsp;{{item.BookingId}})
                </p>
                <p *ngIf="item.TransactionType == 2" style="
                    margin: 5px 0 0 0;
                    padding: 0;
                    text-align: center;
                    color: #000;
                    font-weight: 700;
                    font-size: 18px;
                  ">
                  {{
                  AccountSetup?.SaleRefundInvoiceHeaderTextAR
                  ? AccountSetup?.SaleRefundInvoiceHeaderTextAR
                  : "مرتجع لفاتورة رقم "
                  }}
                  {{ item.RefundedTransactionCodeText }}
                </p>
              </td>
              <td style="text-align: end; width: 30%">
                <p style="
                    font-family: initial;
                    font-size: 21px;

                    margin: 0;
                    font-weight: 1000;
                    color: #000;
                  ">
                  {{ item.Id }}
                </p>
                <p style="
                    font-family: initial;
                    font-size: 21px;
                    margin: 0;
                    font-weight: 1000;
                    color: #000;
                  ">
                  {{ item.TransactionCodeText }}
                </p>
                <!-- <p style="font-family:initial; font-size: 18px; margin: 0;font-weight: 700; color: #000;">{{
                AccountSetup?.TaxRegistrationNumber }} </p> -->
              </td>
            </tr>
          </table>
          <div>
            <hr />
            <table style="width: 100%; text-align: start" [dir]="dirc">
              <tr>
                <td style="
                    font-family: initial;
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                  ">
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px">
                    {{ "Client" | translate }} : {{ item.client?.NameAr }}
                  </p>
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px"
                    *ngIf="AccountSetup?.AllowShowClientNationalityInPrint">
                    {{ "ClientNationality" | translate }} : {{ item.client?.NationalityName ?? ( "NotDefined" |
                    translate) }}
                  </p>
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
                      sharedService.AllowAccessToClientsMobileNumbers == true &&
                      AccountSetup?.PrintReceiptIncludeClientphoneNumber == true
                    ">
                    {{ "Phone" | translate }} : {{ item.client?.Mobile }}
                  </p>
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px">
                    {{ "UserName" | translate }} :
                    {{
                    printLang == "ar"
                    ? item.employee?.NameAr
                    : item.employee?.NameEn
                    }}
                  </p>
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px">
                    <span *ngIf="item.TransactionPlace == 1">{{
                      "InHome" | translate
                      }}</span>
                  </p>

                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
                      sharedService.CurrentBranch
                        .AllowUseNumberOfPrintsInBills &&
                      sharedService.CurrentBranch.AllowShowNumberOfPrintsInBills
                    ">
                    <span>{{ "PrintNumber:" | translate }}</span>
                    {{ (item.NumberOfPrints ?? 0) + 1 }} .
                  </p>

                  <p style="font-size: 18px; font-weight: 600; margin: 5px 0px" *ngIf="
                  sharedService.CurrentBranch
                    .AllowShowTipsPrintBill &&
                    item.Tips > 0 ">
                    <span>{{ "Tips:" | translate }}</span>
                    {{ item.Tips.toFixed(sharedService.GetFractionRoundCount) }} &nbsp; {{CurrencyName}}
                  </p>

                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px">
                    <span *ngIf="
                        item.UserWhoAcceptedRequestPermission &&
                        item.UserWhoAcceptedRequestPermission.length > 0
                      ">
                      {{
                      (item.TransactionType == transactionType.CashSale
                      ? "UserWhoAcceptedDiscountRequestPermission"
                      : item.TransactionType == transactionType.SaleRefund
                      ? "UserWhoAcceptedSaleRefundRequestPermission"
                      : ""
                      ) | translate
                      }}
                      {{ item.UserWhoAcceptedRequestPermission }}
                    </span>
                  </p>

                  <div *ngFor="let p of item.TrProductServices | slice: 0:1">
                    <div *ngIf="p.ItemType == 6">
                      <p style="
                          font-size: 14px;
                          font-weight: 600;
                          margin: 5px 0px;
                        ">
                        رقم العضوية : {{ item.client?.ClientCode }}
                      </p>
                      <p style="font-size: 14px; font-weight: 600; margin: 0px">
                        رقم الهوية : {{ item.client.CardNumber }}
                      </p>
                      <p style="
                          font-size: 14px;
                          font-weight: 600;
                          margin: 5px 0px;
                        ">
                        فئة النشاط :
                        <span *ngFor="
                            let TrProductService of item.TrProductServices
                              | slice: 0:1
                          ">
                          {{ TrProductService.service?.Category?.NameAr }}
                        </span>
                      </p>

                      <h4 style="font-size: 18px; padding: 5px; margin: 0">
                        اشتراك عن الفترة
                      </h4>
                      <p style="
                          font-family: initial;
                          font-size: 18px;
                          margin: 5px 0;
                          font-weight: 700;
                          color: #000;
                        ">
                        <b>{{ "From" | translate }} </b> &nbsp;&nbsp;
                        <span>
                          {{
                          p.GymMembershipStartDate | date: "dd/MM/yyyy"
                          }}</span>
                      </p>
                      <p style="
                          font-family: initial;
                          font-size: 18px;
                          margin: 5px 0;
                          font-weight: 700;
                          color: #000;
                        ">
                        <b>{{ "To" | translate }}</b> &nbsp;&nbsp;
                        <span>
                          {{
                          p.GymMembershipEndDate | date: "dd/MM/yyyy"
                          }}</span>
                      </p>
                    </div>
                  </div>
                </td>

                <td style="text-align: end; padding: 0px">
                  <div *ngIf="AccountSetup?.ShowQRImageInBill == true">
                    <div style="text-align: end" *ngIf="AccountSetup?.QRImagePath">
                      <img [src]="AccountSetup?.QRImagePath" style="width: 100px" />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div *ngIf="item.TrProductServices?.length > 0">
              <table border="1" style="
                  width: 100%;
                  border-collapse: collapse;
                  text-align: start !important;
                  margin: 15px 0;
                  border: 2px solid #000;
                " [dir]="dirc">
                <thead style="text-align: start">
                  <tr style="text-align: start; background: #ebebeb">
                    <th style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start !important;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ "Name" | translate }}
                    </th>
                    <th style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start !important;
                        color: #000;
                        padding: 5px;
                      ">

                      {{ sharedService?.CurrentBranch?.EmployeeShowType == employeeShowType.Doctor ?
                      ('TheDoctor' | translate) :
                      ( (sharedService?.CurrentBranch?.EmployeeShowType == employeeShowType.Trainer
                      ? ('TheTrainer' | translate) :
                      ('TheEmployee'| translate)) ) }}

                    </th>
                    <th style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start !important;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ "Quantity" | translate }}
                    </th>
                    <th style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start !important;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ "Price" | translate }}
                    </th>
                    <th style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start !important;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ "Total" | translate }}
                    </th>

                    <th *ngIf="AccountSetup?.AllowUseClientsQueueSystem && AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
                    AccountSetup?.ClientsQueueSystemType == 2" style="
                    font-family: initial;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: start !important;
                    color: #000;
                    padding: 5px;
                  ">
                      {{ "QueueNumber" | translate }}
                    </th>

                  </tr>
                </thead>
                <tbody *ngFor="
                    let TrProductService of item.TrProductServices;
                    let i = index">

                  <tr style="text-align: start">
                    <td style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        color: #000;
                        padding: 5px;
                      ">
                      {{getBillItemName(TrProductService)}}
                      <div *ngIf="AccountSetup?.AllowUseRooms == true">
                        <span *ngFor="
                            let room of TrProductService.ResourceManagements
                          ">
                          {{ room.ResourceName }} <br />
                          {{ "From" | translate }}:
                          {{ room.StartTime | date: "shortTime" }} ,
                          {{ "To" | translate }}:
                          {{ room.EndTime | date: "shortTime" }}
                        </span>
                      </div>
                    </td>
                    <td style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ TrProductService.Employee?.GlobalName ?? ('NotFound'|translate)}}
                      {{ TrProductService?.SecondEmployee ? " - " + TrProductService.SecondEmployee.GlobalName : ""
                      }}
                    </td>
                    <td style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ TrProductService.Quantity }}
                    </td>
                    <td style="
                        font-family: initial;
                        font-size: 18px;
                        font-weight: 700;
                        color: #000;
                        padding: 5px;
                      ">
                      {{ TrProductService.UnitPrice.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>
                    <td style=" font-family: initial;
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                    padding: 5px; ">
                      {{ TrProductService.Total.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>

                    <td *ngIf="AccountSetup?.AllowUseClientsQueueSystem && AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
                    AccountSetup?.ClientsQueueSystemType == 2" style="
                    font-family: initial;
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                    padding: 5px; ">
                      {{ TrProductService.ClientNumberInQueue }}
                    </td>

                  </tr>

                  <tr style="text-align: start" *ngIf="TrProductService.IsEmployeeCommissionForClients">
                    <td colspan="10" style=" font-family: initial; font-size: 13px; font-weight: 400;
                    color: #000; padding: 5px;">
                      {{ "CommissionAtTheRequestOfAClient" | translate }}
                    </td>
                  </tr>

                  <tr style="text-align: start"
                    *ngIf="TrProductService.GiftCardsCodes && TrProductService.GiftCardsCodes.length > 0">
                    <td colspan="2" style=" font-family: initial; font-size: 18px; font-weight: 700;
                    color: #000; padding: 5px;">
                      {{ "TheGiftCardCode" | translate }}
                    </td>
                    <td colspan="8" style=" font-family: initial; font-size: 18px; font-weight: 700;
                    color: #000; padding: 5px;">
                      <span *ngFor=" let giftCardsCode of TrProductService.GiftCardsCodes;
                      let i = index;">
                        {{ giftCardsCode }}
                        <span *ngIf="i+1 != TrProductService.GiftCardsCodes.length"> -
                        </span>
                      </span>
                    </td>
                  </tr>

                  <tr *ngIf="
                      TrProductService?.TransactionsProductOrServiceSessions &&
                      TrProductService?.TransactionsProductOrServiceSessions
                        ?.length > 0
                    ">
                    <td colspan="4">
                      <table border="1" style="width: 100%">
                        <tr *ngFor="
                            let sessionervice of TrProductService?.TransactionsProductOrServiceSessions;
                            let i = index
                          ">
                          <!-- <td> {{'Sessions' | translate }} </td> -->
                          <td>
                            {{
                            printLang == "ar"
                            ? sessionervice.ServiceSession?.NameAr
                            : sessionervice.Service?.NameEn || ""
                            }}
                          </td>
                          <td style="color: #000; padding: 5px">
                            {{
                            printLang == "ar"
                            ? sessionervice.Employee?.NameAr
                            : sessionervice.Employee?.NameEn || ""
                            }}
                          </td>
                          <td>{{ sessionervice.Price }}</td>
                          <td>{{ sessionervice.Total }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr *ngIf="
                      TrProductService.TransactionsPackageServices &&
                      TrProductService.TransactionsPackageServices.length != 0
                    ">
                    <!-- <td> {{'Package' | translate}}</td> -->
                    <td colspan="9">
                      <table border="1" style="width: 100%">
                        <tr *ngFor="
                            let packageservice of TrProductService.TransactionsPackageServices;
                            let i = index
                          ">
                          <td>
                            <p style="font-size: 13px">
                              {{
                              printLang ==
                              "ar"
                              ? packageservice.Service?.NameAr
                              : packageservice.Service?.NameEn
                              }}
                            </p>
                          </td>
                          <td style="color: #000; padding: 5px">
                            {{
                            printLang == "ar"
                            ? packageservice.Employee?.NameAr
                            : packageservice.Employee?.NameEn
                            }}
                          </td>
                          <td>
                            {{
                            sharedService.getServicePackagePrice(TrProductService.UnitPrice,TrProductService.TransactionsPackageServices,packageservice.ServiceOriginalPrice).toFixed(sharedService.GetFractionRoundCount)
                            }}
                          </td>
                          <td>
                            {{
                            sharedService.getServicePackagePrice(TrProductService.Total,TrProductService.TransactionsPackageServices,packageservice.ServiceOriginalPrice).toFixed(sharedService.GetFractionRoundCount)
                            }}
                          </td>
                          <td *ngIf="packageservice?.ClientNumberInQueue">
                            {{ packageservice?.ClientNumberInQueue }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table *ngIf="item.Comment">
              <tr>
                <td>
                  {{ item.Comment }}
                </td>
              </tr>
            </table>

            <hr />
            <div>
              <table style="width: 100%; text-align: start" [dir]="dirc">
                <tr>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalBeforeDiscounts" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>{{item.PriceBeforeDiscount.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="item.WalletDiscountValue > 0">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "WalletDiscount" | translate }}
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ item.WalletDiscountValue.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "ItemDiscounts" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ +(item.DiscountValue).toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalAfterDiscounts" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ item.PriceAfterDiscount.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>




                <tr *ngIf="item.UsedClientDownPayment > 0">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "ClientDownPayment" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ item.UsedClientDownPayment.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>




                <tr>
                  <td *ngIf="sharedService.AllowUseSalesTaxRate == true" style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalIncludeTax" | translate }} :
                  </td>
                  <td *ngIf="sharedService.AllowUseSalesTaxRate == false" style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalAllAfterDis" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>{{ item.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "ValueAddedTax" | translate }} ( %
                    {{ AccountSetup?.SalesTaxRate }} ) :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ item.TaxValue.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUsePayForPostpaidBillsWhenCreateBills &&
                    sharedService.AllowUsePostpaidSystemAndClientBalances &&
                    item.TotalPaidAmountForPostpaidBillsWithCashSale &&
                    item.TotalPaidAmountForPostpaidBillsWithCashSale > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "PaidForPostpaidBills" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      item.TotalPaidAmountForPostpaidBillsWithCashSale.toFixed(
                      2
                      )
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUsePayForPostpaidBillsWhenCreateBills &&
                    sharedService.AllowUsePostpaidSystemAndClientBalances &&
                    item.TotalPaidAmountForPostpaidBillsWithCashSale &&
                    item.TotalPaidAmountForPostpaidBillsWithCashSale > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalAfterPaidForPostpaidBills" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      (
                      item.TotalNetPrice +
                      item.TotalPaidAmountForPostpaidBillsWithCashSale
                      ).toFixed(sharedService.GetFractionRoundCount)
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUsePostpaidSystemAndClientBalances &&
                    item.RemainingAmountFromPostpaid &&
                    item.RemainingAmountFromPostpaid > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "RemainingAmountFromPostpaid" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{ item.RemainingAmountFromPostpaid.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUsePostpaidSystemAndClientBalances &&
                    item.RemainingAmountFromPostpaid &&
                    item.RemainingAmountFromPostpaid > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalAfterRemainingAmountFromPostpaid" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      (
                      item.TotalNetPrice - item.RemainingAmountFromPostpaid
                      ).toFixed(sharedService.GetFractionRoundCount)
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUseClientsBalancesInSales &&
                    item.UseClientBalance &&
                    item.UsedClientBalance > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "UsedClientBalanceInPayment" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span> {{ item.UsedClientBalance.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUseClientsBalancesInSales &&
                    item.UseClientBalance &&
                    item.UsedClientBalance > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "TotalAfterUsedClientBalance" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      (item.TotalNetPrice - item.UsedClientBalance).toFixed(sharedService.GetFractionRoundCount)
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUseAddRemainingAmountToClientBalance &&
                    item.AddedRemainingAmountToClientBalance &&
                    item.AddedRemainingAmountToClientBalance > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "RemainingAmountAddedToClientBalance" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      item.AddedRemainingAmountToClientBalance.toFixed(sharedService.GetFractionRoundCount)
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="
                    sharedService.AllowUseAddRemainingAmountToClientBalance &&
                    item.AddedRemainingAmountToClientBalance &&
                    item.AddedRemainingAmountToClientBalance > 0
                  ">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{
                    "TotalAfterAddedRemainingAmountToClientBalance"
                    | translate
                    }}
                    :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span>
                      {{
                      (
                      item.TotalNetPrice +
                      item.AddedRemainingAmountToClientBalance
                      ).toFixed(sharedService.GetFractionRoundCount)
                      }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "PaymentTypes" | translate }} :
                  </td>
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;
                    ">
                    <span *ngFor="let p of item.TransactionsPayments">
                      {{ p?.PaymentMethod?.NameAr }}

                      : {{ p.Amount.toFixed(sharedService.GetFractionRoundCount) }}

                      <span style="margin-inline-start: 10px">
                        {{
                        printLang == "ar"
                        ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn
                        }}
                      </span>

                      <span *ngIf="
                          p.PaymentMethod?.PaymentMethodType ==
                          paymentMethodType.GiftCard
                        ">
                        - {{ "CardCode" | translate }} : {{ p.GiftCardCode }} -
                      </span>
                      <!-- <div *ngIf="item.WalletDiscountValue != 0">
                      {{ 'Wallet' | translate }} : {{item.WalletDiscountValue}} {{printLang== 'ar' ?
                      sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </div> -->
                    </span><br />
                    <span *ngIf="item.UsedClientDownPayment != 0">
                      {{ "DownPayment" | translate }} :
                      {{ item.UsedClientDownPayment.toFixed(sharedService.GetFractionRoundCount) }}
                      <span style="margin-inline-start: 10px">
                        {{
                        printLang == "ar"
                        ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn
                        }}
                      </span>
                    </span>
                  </td>
                </tr>
              </table>

              <div *ngIf="
                  item.TrProductServices?.length > 0 &&
                  AccountSetup?.ShowServiceImageInBill == true
                ">
                <div *ngFor="
                    let TrProductService of item.TrProductServices;
                    let i = index
                  ">
                  <table *ngIf="
                      TrProductService.TransactionsProductOrServiceImages
                        ?.length != 0
                    ">
                    <tbody *ngFor="
                        let images of TrProductService.TransactionsProductOrServiceImages
                      ">
                      <tr>
                        <td style="text-align: center">
                          <img style="width: 80%; margin: 0px auto" src="{{ images.ServiceImage?.ImagePath }}" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li *ngFor="
                                let notes of images.TransactionsProductOrServiceImageNotes
                              ">
                              {{ notes.NoteIndex + 1 }} - {{ notes.NoteText }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="
                width: 100%;
                height: 1px;
                border: 1px solid #000;
                margin: 3px 5px;
              "></div>
            <p style="
                text-align: center;
                font-weight: 700;
                color: #000;
                margin: 10px 0px;
                font-family: initial;
                font-size: 16px;
                font-weight: 700;
              ">
              {{ "Phone" | translate }} : {{ AccountSetup?.PhoneNumber }} -
              {{ AccountSetup?.MobileNumber }}
            </p>

            <p *ngIf="AccountSetup?.AllowShowCommercialRecordNumberInPrint && AccountSetup?.CommercialRecordNumber && AccountSetup?.CommercialRecordNumber.length > 0"
              style="
                text-align: center;
                font-weight: 700;
                color: #000;
                margin: 10px 0px;
                font-family: initial;
                font-size: 16px;
                font-weight: 700;
              ">
              {{ "CommercialRecordNumber" | translate }}:
              {{ AccountSetup?.CommercialRecordNumber }}
            </p>

            <p *ngIf="AccountSetup?.AllowShowTaxRegistrationNumberInPrint && AccountSetup?.TaxRegistrationNumber && AccountSetup?.TaxRegistrationNumber.length > 0"
              style="
                text-align: center;
                font-weight: 700;
                color: #000;
                margin: 10px 0px;
                font-family: initial;
                font-size: 16px;
                font-weight: 700;
              ">
              {{ "TaxRegistrationNumber" | translate }}:
              {{ AccountSetup?.TaxRegistrationNumber }}
            </p>

            <p style="
                text-align: center;
                font-weight: 700;
                color: #000;
                margin: 10px 0px;
                font-family: initial;
                font-size: 16px;
                font-weight: 700;
                white-space: break-spaces;
              ">
              {{ "Address" | translate }} : {{ AccountSetup?.Address }}
            </p>
            <div style="
                width: 100%;
                height: 1px;
                border: 1px solid #000;
                margin: 3px 5px;
              "></div>
            <p style="
                text-align: center;
                font-weight: 700;
                color: #000;
                font-family: initial;
                font-size: 16px;
                font-weight: 700;
              ">
              {{ AccountSetup?.PrintReceiptAdvertText }}
            </p>



            <div style="display: flex; align-items: center; justify-content: center;">
              <span *ngIf="AccountSetup?.AllowUseClientsQueueSystem &&
                AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
                AccountSetup?.ClientsQueueSystemType == 1 &&
                item.ClientNumberInQueue && item.ClientNumberInQueue > 0" style="margin-right:30px;">

                <div style="text-align: center;">
                  <span style="display: block; font-size: 25px; font-weight: 700;">{{ "ClientNumberInQueue" |
                    translate
                    }}</span>
                </div>
                <p style="text-align: center; font-weight: 700; color: #000;
      font-family: initial;font-size: 60px; font-weight: 700;margin: 15; background-color: #373333  !important;
      color: white;padding: 5px; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
                  <span>{{ item?.ClientNumberInQueue }}</span>
                </p>
              </span>

              <div style="text-align: center;" *ngIf="AccountSetup?.AllowGenerateQRCode">
                <ngx-qrcode [width]="sharedService.GetQRCodeSize()" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                  [value]="item.ZaqatQRCode" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>
                <hr>

              </div>
            </div>

          </div>
        </div>

        <!-- Items -->
        <div style="text-align: start;direction: rtl;
        width: 100%; margin: 0;background-color: #fff;" [dir]="dirc">

          <div [ngStyle]="{ 'page-break-after': e != item.TransactionItems.length - 1 ? 'always' : '' }"
            *ngFor="let transItem of item.TransactionItems; let e = index">

            <div style="">

              <div class="row">
                <div class="col-lg-12 " style="text-align: center;">
                  <div style="text-align: center;width: 100%;" *ngIf="AccountSetup?.ImagePath">
                    <img [src]="AccountSetup?.ImagePath" style="width: 100px;text-align: center;" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 " style="text-align: center;">
                  <p class="hed" style="
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  text-align: center;
                  color: #000;
                  font-weight: 600;
                  font-size: 16px; ">
                    {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr : sharedService.BranchNameEn }} &nbsp; {{
                    printLang }}
                  </p>
                </div>
              </div>

              <hr />

              <table style="width: 100%" [dir]="dirc">
                <!-- <tr class="row">
                  <td class="col-12 text-start">
                    <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
                      <img [src]="AccountSetup?.ImagePath" style="width: 60px" />
                    </div>
                    <p class="hed" style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px; ">
                      {{ sharedService.BranchNameAr
                      }} &nbsp; {{ printLang }}
                    </p>
                  </td>
                </tr> -->

                <tr class="row">
                  <td class="col-6 text-start">
                    <p style="
                        font-family: initial;
                        font-size: 18px;
                        margin: 0;
                        font-weight: 600;
                        color: #000;
                      ">
                      {{ item.Date | date: "dd/MM/yyyy" }}
                    </p>

                    <p style="
                    font-family: initial;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 600;
                    color: #000;
                  ">
                      {{ item.Date | date: "hh:mm a" }}
                    </p>

                    <p *ngIf="item.BookingDate" style="
                        font-family: initial;
                        font-size: 18px;
                        margin: 0;
                        font-weight: 600;
                        color: #000;
                      ">
                      {{ item.BookingDate | date: "dd/MM/yyyy hh:mm a" }}
                    </p>

                    <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
                    sharedService.CurrentBranch
                      .AllowUseNumberOfPrintsInBills &&
                    sharedService.CurrentBranch.AllowShowNumberOfPrintsInBills
                  ">
                      <span>{{ "PrintNumber:" | translate }}</span>
                      {{ (item.ItemsNumberOfPrints ?? 0) + 1 }} .
                    </p>

                    <p class="hed" *ngIf="item.BookingId"
                      style="margin: 5px 0  0 0; padding: 0;color: #430fd4; text-align: center; font-weight: 600; font-size: 16px;">
                      {{'ForBookingPayment' | translate }} ( {{'Number' | translate }}
                      &nbsp;{{item.BookingId}})F
                    </p>

                  </td>

                  <td class="col-6 text-center">


                    <p style="
                    font-family: initial;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 600;
                    color: #000;
                  ">
                      {{ item.Id }}
                    </p>

                    <p class="hed" *ngIf="item.TransactionType == 2 ||
                    item.TransactionType == 1 " style="
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        color: #000;
                        font-weight: 600;
                        font-size: 16px;
                      ">
                      <!-- {{'BillNumber' | translate }}&nbsp;-&nbsp; -->{{item.TransactionCodeText}}
                    </p>


                  </td>

                </tr>
              </table>
              <div>

                <div class="row">
                  <div class="col-lg-12 " style="text-align: center;">
                    <h2 style=" color:#000; font-weight:900;">
                      {{ ('PrintItems'|translate) }}
                    </h2>
                  </div>
                  <div class="col-lg-12 " style="text-align: center;">
                    <h4 style="color:#000; font-weight:900;">
                      {{ ('ItemName'|translate) + " - " + getItemName(transItem) }}
                    </h4>
                  </div>
                </div>

                <!-- [dir]="printLang  == 'ar' ? 'rtl' : 'ltr'" -->
                <table style="width: 100%; text-align: start" [dir]="dirc">
                  <tr>
                    <td style="
          font-family: initial;
          font-size: 20px;
          font-weight: 500;
          width: 160px;
          color: #000;
        ">
                      <p style="font-size: 16px; font-weight: 600; margin: 0px">
                        {{ "ClientName" | translate }} :
                        {{
                        printLang == "ar"
                        ? item.client?.NameAr
                        : item.client?.NameEn
                        }}
                      </p>

                      <p style="font-size: 16px; font-weight: 600; margin: 0px">
                        {{ "UserName" | translate }} :
                        {{
                        printLang == "ar"
                        ? item.employee?.NameAr
                        : item.employee?.NameEn
                        }}
                      </p>
                    </td>
                    <td style="text-align: end">
                      <div style="text-align: end" *ngIf="AccountSetup?.QRImagePath">
                        <img [src]="AccountSetup?.QRImagePath" alt="" style="width: 80px" />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <div *ngIf="item.TrProductServices.length > 0">
                  <table border="1" style="
        width: 100%;
        border-collapse: collapse;
        text-align: start !important;
        margin: 15px 0;
        border: 2px solid #000;
      " [dir]="dirc">
                    <thead style="text-align: start !important" [dir]="dirc">
                      <tr style="text-align: start !important" [dir]="dirc">
                        <th style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Name" | translate }}
                        </th>
                        <th style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Employee" | translate }}
                        </th>
                        <th style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Quantity" | translate }}
                        </th>
                        <th style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Price" | translate }}
                        </th>
                        <!-- <th style="text-align: start !important; color: #000; padding:5px;">{{ 'Commission' | translate }}
          </th> -->
                        <th style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Total" | translate }}
                        </th>
                        <th *ngIf="sharedService?.CurrentBranch?.AllowTracingCommissionInCashSale == true &&
           this.hasNonNullEmployeeTransactionCommission(item)"
                          style="text-align: start !important; color: #000; padding: 5px">
                          {{ "Commission" | translate }}
                        </th>

                        <!-- <th *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
          sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
          sharedService?.CurrentBranch?.ClientsQueueSystemType == 2"
                          style="text-align: start !important; color: #000; padding: 5px">
                          {{ "QueueNumber" | translate }}
                        </th> -->

                      </tr>
                    </thead>
                    <tbody>
                      <tr style="text-align: start">
                        <td style="color: #000; padding: 5px">
                          {{ (transItem.Note == "PackageService" ? (('PackageService'|translate) + " - ") : "") +
                          (transItem.service
                          ? printLang == "ar"
                          ? transItem.service.NameAr
                          : transItem.service.NameEn
                          : transItem.Service
                          ? printLang == "ar"
                          ? transItem.Service.NameAr
                          : transItem.Service.NameEn
                          : transItem.product
                          ? printLang == "ar"
                          ? transItem.product.NameAr
                          : transItem.product.NameEn
                          : transItem.Package
                          ? printLang == "ar"
                          ? transItem.Package.NameAr
                          : transItem.Package.NameEn
                          : transItem.OffersSetting
                          ? printLang == "ar"
                          ? transItem.OffersSetting.NameAr
                          : transItem.OffersSetting.NameEn
                          : "")
                          }}
                          <div *ngIf="AccountSetup?.AllowUseRooms == true">
                            <span *ngFor="
                  let room of transItem.ResourceManagements
                ">
                              {{ room.ResourceName }} <br />
                              {{ "From" | translate }}:
                              {{ room.StartTime | date: "shortTime" }} ,
                              {{ "To" | translate }}:
                              {{ room.EndTime | date: "shortTime" }}
                            </span>
                          </div>
                        </td>
                        <td style="color: #000; padding: 5px">


                          {{ transItem.Employee ? (printLang == "ar" ?
                          transItem.Employee?.NameAr :
                          transItem.Employee?.NameEn ): ('NotFound'|translate)}}


                          {{ transItem?.SecondEmployee ? " - " +
                          (printLang == "ar" ?
                          transItem.SecondEmployee?.NameAr :
                          transItem.SecondEmployee?.NameEn) : "" }}
                        </td>
                        <td style="color: #000; padding: 5px">
                          {{ transItem.Quantity }}
                        </td>
                        <td style="color: #000; padding: 5px">
                          <span *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                            {{ transItem?.OriginalPrice?.toFixed(sharedService.GetFractionRoundCount) }}
                          </span>
                          <span *ngIf="!sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                            {{ "NotAllowed" | translate }}
                          </span>
                        </td>
                        <!-- <td style="color: #000; padding:5px;">
            {{TrProductService?.EmployeeTransactionCommission[0]?.CommissionValue }} </td> -->
                        <td style="color: #000; padding: 5px">
                          <span *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                            {{ transItem?.Total?.toFixed(sharedService.GetFractionRoundCount) }}
                          </span>
                          <span *ngIf="!sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                            {{ "NotAllowed" | translate }}
                          </span>
                        </td>
                        <td *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
           && transItem?.EmployeeTransactionCommission != null
           && transItem.EmployeeTransactionCommission?.CommissionValue > 0" style="color: #000; padding: 5px">

                          <span *ngIf="transItem.EmployeeTransactionCommission?.OverTimeCommissionId != null">
                            {{ "ServiceFeeOvertime" | translate }}
                          </span>
                          <span>
                            {{
                            transItem.EmployeeTransactionCommission?.CommissionValue.toFixed(sharedService.GetFractionRoundCount)
                            }}
                          </span>
                        </td>

                        <!--  <td *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
          sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
          sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="color: #000; padding: 5px">
                          {{ transItem.ClientNumberInQueue }}
                        </td> -->
                      </tr>

                      <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
             && transItem?.EmployeeTransactionCommission != null
             && transItem?.EmployeeTransactionCommission?.HrCommissionSettingDetailId == null
             && transItem?.EmployeeTransactionCommission?.TargetCommissionValue != 0
             && transItem.ItemId === transItem.ItemId">
                        <td colspan="6">
                          <div style="display: flex; justify-content: space-between">

                            <p style="padding: 0px; margin: 0px; font-size: 12px">
                              {{ "Target" | translate }} :

                              {{ transItem?.EmployeeTransactionCommission?.TargetCommissionValue }}
                              {{ transItem?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                              (sharedService.Lang == 'ar' ? (sharedService.AccountCurrency?.NameAr | translate) :
                              (sharedService.AccountCurrency?.NameEn | translate)) : ('Servicess' | translate) }}:


                            </p>

                          </div>
                          <div style="display: flex; justify-content: space-between">
                            <p style="padding: 0px; margin: 0px; font-size: 12px">
                              {{ "TotalInvestigator" | translate }} :

                              {{ transItem?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                              ( transItem?.EmployeeTransactionCommission?.TotalAchievedServicePrice) :
                              ( transItem?.EmployeeTransactionCommission?.TotalInvoicesCount)}}
                            </p>
                            <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                  transItem?.EmployeeTransactionCommission?.IsTargetAccomplished == true">
                              {{ "TargetAchieved" | translate }}
                            </p>
                            <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                  transItem?.EmployeeTransactionCommission?.IsTargetAccomplished == false">
                              لم يتم تحقيق التارجت حتى الأن
                            </p>
                          </div>
                          <div style="display: flex; justify-content: space-between">
                            <p style="padding: 0px; margin: 0px; font-size: 12px">
                              الايام المتبقية لتحقيق التارجت :
                              {{ transItem?.EmployeeTransactionCommission?.RemainingTargetDays }}
                            </p>
                          </div>

                        </td>

                      </tr>

                      <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
        && transItem?.EmployeeTransactionCommission == null
        && transItem.ItemId === transItem.ItemId">

                        <td colspan="6">
                          <div style="display: flex; justify-content: space-between">
                            <p style="padding: 0px; margin: 0px; font-size: 12px">
                              {{ "NoCommissionCalculated" | translate }}
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p style="
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      color: #000;
    ">
                  {{ item.Comment }}
                </p>

                <div style="display: flex; align-items: center; justify-content: center;">
                  <span *ngIf="AccountSetup?.AllowUseClientsQueueSystem &&
                    AccountSetup?.AllowShowClientsQueueNumberInPrintEmployees &&
                    AccountSetup?.ClientsQueueSystemType == 1 &&
                    item.ClientNumberInQueue && item.ClientNumberInQueue > 0" style="margin-right:30px;">

                    <!-- <div style="text-align: center;">
                      <span style="display: block; font-size: 25px; font-weight: 700;">{{ "ClientNumberInQueue" |
                        translate
                        }}</span>
                    </div> -->
                    <p style="text-align: center; font-weight: 700; color: #000;
                        font-family: initial;font-size: 30px; background-color: #373333  !important;
                        color: white; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
                      <span>{{ item?.ClientNumberInQueue }}</span>
                    </p>
                  </span>
                </div>


                <div style="display: flex; align-items: center; justify-content: center;">
                  <span *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
                  sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
                  sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="margin-right:30px;">

                    <!--  <div style="text-align: center;">
                      <span style="display: block; font-size: 25px; font-weight: 700;">{{ "ClientNumberInQueue" |
                        translate
                        }}</span>
                    </div> -->

                    <p *ngIf="getCategoryNameName(transItem)" style="text-align: center; font-weight: 700; color: #000;
                        font-family: initial;font-size: 20px; font-weight: 700; background-color: #373333  !important;
                        color: white; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
                      <span>{{ getCategoryNameName(transItem) }}</span>
                    </p>

                    <p style="text-align: center; font-weight: 700; color: #000;
                        font-family: initial;font-size: 30px; font-weight: 700;background-color: #373333  !important;
                        color: white;border-radius: 5px; -webkit-print-color-adjust: exact !important;">
                      <span>{{ transItem.ClientNumberInQueue }}</span>
                    </p>
                  </span>
                </div>

                <span *ngIf="sharedService.CurrentBranch.AllowChangeBillItemsEmployeesByQRCode">

                  <div class="row">
                    <div class="col-lg-12 " style="text-align: center;">
                      <h3 style="color:#000; font-weight:900;">
                        {{ ('ScanToChangeItemEmployeeToYourself'|translate )}}
                      </h3>
                    </div>
                    <div class="col-lg-12 " style="text-align: center;">
                      <h4 style=" color:#000; font-weight:900;">
                        {{ 'ItemName'|translate }} :

                        {{(transItem.Note == "PackageService" ? (('PackageService'|translate) + " - ") : "") +
                        (transItem.service
                        ? printLang == "ar"
                        ? transItem.service.NameAr
                        : transItem.service.NameEn
                        : transItem.Service
                        ? printLang == "ar"
                        ? transItem.Service.NameAr
                        : transItem.Service.NameEn
                        : transItem.product
                        ? printLang == "ar"
                        ? transItem.product.NameAr
                        : transItem.product.NameEn
                        : transItem.Package
                        ? printLang == "ar"
                        ? transItem.Package.NameAr
                        : transItem.Package.NameEn
                        : transItem.OffersSetting
                        ? printLang == "ar"
                        ? transItem.OffersSetting.NameAr
                        : transItem.OffersSetting.NameEn
                        : "")
                        }}
                      </h4>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 " style="text-align: center;">

                      <div style="display: flex; align-items: center; justify-content: center;">
                        <div style="text-align: center;">
                          <ngx-qrcode [width]="250" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                            [value]="(transItem.Note == 'PackageService' ?
                            BillInfoForPackageUrl : BillInfoUrl) + transItem.Id" alt="Demo QR Code"
                            cssClass="bshadow"></ngx-qrcode>
                          <hr>

                        </div>
                      </div>

                    </div>
                  </div>
                </span>

              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>
