import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { GiftCardInformationService } from 'src/app/services/gift-card/gift.card.information.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Attachment } from 'src/app/models/classes/transactions/attachment';
import { ResponseState } from 'src/app/models/support/response-state';
import { ModalUploadAttachementsComponent } from '../modal-upload-attachements/modal-upload-attachements.component';
import { SupplierService } from 'src/app/services/other/supplier.service';

@Component({
  selector: 'app-modal-add-update-supplier',
  templateUrl: './modal-add-update-supplier.component.html',
  styleUrls: ['./modal-add-update-supplier.component.scss'],
  providers: [DatePipe],
})
export class ModalAddUpdateSupplierComponent implements OnInit {

  @Input() SupplierId: number;
  public attachmentsFiles: Attachment[] = [];
  @Output() AddedOrUpdated = new EventEmitter<boolean>();
  @ViewChild('attachmentsComponent', { static: false })
  attachmentsComponent: AttachmentsComponent;
  public LoadingGetSupplier: boolean;
  Attachments: UntypedFormArray;
  GForm: UntypedFormGroup;
  freeTextGetPettyCashItems: string = null;

  public accountPayments: any;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private supplierService: SupplierService,
    private ngbModal: NgbModal,
    public toaster: ToastrService,
    public giftCardInformationService: GiftCardInformationService,
    public clientBalanceService: ClientBalanceService
  ) { }

  ngOnInit(): void {

    this.GFormDate();

    if (this.SupplierId && this.SupplierId > 0) {
      this.GetSupplier(this.SupplierId);
    }
  }

  supplier
  GetSupplier(supplierId: number) {
    this.LoadingGetSupplier = true;
    return this.supplierService.getSupplier(supplierId).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.supplier = response.Result;
        this.GForm.patchValue(this.supplier);
        this.attachmentsFiles = this.supplier.Attachments ?? [];
      }
      this.LoadingGetSupplier = false;
    });
  }

  CreateData() {

    if (this.attachmentsFiles) {

      this.Attachments = this.GForm.get('Attachments') as UntypedFormArray;

      this.Attachments.clear();

      this.attachmentsFiles.forEach((attachmentsFile) => {
        this.Attachments.push(
          this.fb.group({
            AttachmentId: attachmentsFile.Id,
            SupplierId: attachmentsFile.SupplierId,
            FolderName: attachmentsFile.FolderName,
            FileName: attachmentsFile.FileName,
            FileType: attachmentsFile.FileType,
            FilePath: attachmentsFile.FilePath,
            FileNotes: attachmentsFile.FileNotes,
          })
        );
      });
    }

    if (this.GForm.value.Id == 0) {
      this.Create();
    }
    else {
      this.Update();
    }
  }

  openModalUploadAttachements() {

    const modalRef = this.ngbModal.open(ModalUploadAttachementsComponent, {
      windowClass: 'myCustomModalClasMinWidth70',
    });

    modalRef.componentInstance.attachmentsFiles = this.attachmentsFiles;
    modalRef.componentInstance.CloseModal.subscribe((result: Attachment[]) => {

      if (result) {
        this.attachmentsFiles = result;
      }

    });

  }

  onSearch(e) {
    this.freeTextGetPettyCashItems = e?.term ?? null;
  }

  btnLoading = false;

  GFormDate() {

    this.GForm = this.fb.group({
      Id: [0],
      Image: [null],
      Name: ['', Validators.required],
      PhoneNumber: [''],
      MobileNumber: [''],
      OtherPhoneNumber: [''],
      Address: [''],
      Postcode: [''],
      Status: [1],
      Notes: [''],
      SupplierTaxNumber : [''],
      AccountSetupId: [this.sharedService.AccountSetupId],
      Attachments: this.fb.array([])
    });
  }

  onAttachmentsComponentInitialized(attachmentsComponent: AttachmentsComponent) {
    this.attachmentsComponent = attachmentsComponent;


    if (this.attachmentsFiles && this.attachmentsFiles.length > 0) {
      this.attachmentsComponent.update(
        this.attachmentsFiles.map(
          ({
            Id,
            BookingId,
            TransactionId,
            JournalId,

            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          }) => ({
            Id,
            BookingId,
            TransactionId,
            JournalId,
            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          })
        )
      );
    }
  }


  divName;
  printPage() {
    this.divName = 'printClientGiftCardInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.attachmentsFiles = this.attachmentsComponent.attachmentsFiles;
  }

  closeModal() {
    this.activeModal.close('Close click');
  }


  Create() {

    this.btnLoading = true;
    this.GForm.controls.AccountSetupId.setValue(this.sharedService.AccountSetupId);

    this.supplierService.addSupplier(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.AddedOrUpdated.emit(true);
        this.closeModal();
        this.btnLoading = false;
      }
      else {
        this.sharedService.ToastrError(response.Message)
      }
      this.btnLoading = false;
    });
  }
  Update() {

    this.btnLoading = true;
    this.GForm.controls.AccountSetupId.setValue(this.sharedService.AccountSetupId);

    this.supplierService.updateSupplier(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.AddedOrUpdated.emit(true);
        this.closeModal();
        this.btnLoading = false;
      }
      else {
        this.sharedService.ToastrError(response.Message)
      }
      this.btnLoading = false;
    });
  }


}
