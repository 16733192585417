<div class="print-wrap">
  <div class="" style="width:100%; padding:20px;margin:0px auto;">
    <div *ngIf="item">
      <div id="print" class="printBox" style="background-color: #fff;">
        <div style="text-align: start; direction: rtl; width: 100%; margin: 0; background-color: #fff;"
          [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">

          <table style="width: 100%;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
            <tr>
              <td style="width: 25%;">
                <p style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">
                  {{ "BillDate" | translate }} {{ item.Date | date:'dd/MM/yy'}}</p>
                <p style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">
                  {{ "BillTime" | translate }} {{ item.Date | date:'HH:mm'}}</p>
                <p *ngIf="item.BookingDate"
                  style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">
                  {{ "BookingDate" | translate }} {{ item.BookingDate | date:'dd/MM/yy'}}</p>
                <p *ngIf="item.BookingDate"
                  style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">
                  {{ "BookingTime" | translate }} {{ item.BookingDate | date:'HH:mm'}}</p>
              </td>
              <td style="width: 50%;">
                <div style="text-align: center;" *ngIf="AccountSetup?.ImagePath">
                  <img [src]="AccountSetup?.ImagePath" style="width: 160px;">
                </div>
                <p class="hed" style="margin: 5px 0  0 0; padding: 0; text-align: center; color: #000;
                font-weight: 700; font-size: 48px;"> {{ sharedService.BranchNameAr }}
                </p>
                <p style="margin: 5px 0  0 0; padding: 0; text-align: center; color: #000;
              font-weight: 700; font-size: 48px;">

                  {{AccountSetup?.SaleRefundInvoiceHeaderTextAR ?
                  (sharedService.Lang == 'ar' ? AccountSetup?.SaleRefundInvoiceHeaderTextAR :
                  AccountSetup?.SaleRefundInvoiceHeaderTextEN) :
                  ('SimplifiedInvoiceCreditNote' | translate )}}

                </p>

                <p *ngIf="item.BookingId" style="margin: 5px 0  0 0; color: #430fd4; padding: 0; text-align: center;
                font-weight: 700; font-size: 30px;">

                  {{'ForBookingPayment' | translate }} ( {{'Number' | translate }}
                  &nbsp;{{item.BookingId}})

                </p>


                <p *ngIf="item.TransactionType == 2" style="margin: 5px 0  0 0; padding: 0; text-align: center; color: #000;
               font-weight: 700; font-size: 48px;">
                  {{AccountSetup?.SaleRefundInvoiceHeaderTextAR ? AccountSetup?.SaleRefundInvoiceHeaderTextAR :
                  ("RefundForBillNumber"|translate)}}
                  {{item.RefundedTransactionCodeText}} </p>

              </td>
              <td style="text-align: end; width: 25%;">
                <p style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">{{item.Id}}
                </p>
                <p style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">
                  {{item.TransactionCodeText}}</p>
                <!-- <p style="font-family:initial; font-size: 48px; margin: 0;font-weight: 700; color: #000;">{{
                AccountSetup?.TaxRegistrationNumber }} </p> -->
              </td>
            </tr>
          </table>
          <div>
            <hr>
            <table style="width: 100%; text-align: start;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
              <tr>
                <td style="font-family:initial; font-size: 48px;font-weight: 700; ; color: #000;">

                  <p style="font-size: 48px; font-weight:600; margin:5px 0px;">
                    {{'Client' | translate }} : {{ item.client?.NameAr }}
                  </p>
                  <p style="font-size: 14px; font-weight: 600; margin: 5px 0px"
                    *ngIf="AccountSetup?.AllowShowClientNationalityInPrint">
                    {{ "ClientNationality" | translate }} : {{ item.client?.NationalityName ?? ( "NotDefined" |
                    translate) }}
                  </p>
                  <p style="font-size: 48px; font-weight:600; margin:5px 0px;"
                    *ngIf="sharedService.AllowAccessToClientsMobileNumbers == true && AccountSetup.PrintReceiptIncludeClientphoneNumber == true">
                    {{'Phone' | translate }} : {{ item.client?.Mobile }}
                  </p>
                  <p style="font-size: 48px; font-weight:600; margin:5px 0px;">
                    {{ 'UserName' | translate }} : {{ sharedService.Lang == 'ar' ? item.employee?.NameAr :
                    item.employee?.NameEn}}
                  </p>
                  <p style="font-size: 48px; font-weight:600; margin:5px 0px;">
                    <span *ngIf="item.TransactionPlace == 1">{{'InHome' | translate}}</span>
                  </p>

                  <p style="font-size: 35px; font-weight:600; margin:5px 0px;">
                    <span *ngIf="item.UserWhoAcceptedRequestPermission  &&
                     item.UserWhoAcceptedRequestPermission.length > 0">
                      {{( ( item.TransactionType == transactionType.CashSale ?
                      ('UserWhoAcceptedDiscountRequestPermission') :
                      item.TransactionType == transactionType.SaleRefund ?
                      ('UserWhoAcceptedSaleRefundRequestPermission') : "" ) | translate)}}
                      {{item.UserWhoAcceptedRequestPermission}}
                    </span>
                  </p>

                  <div *ngFor="let p of item.TrProductServices | slice:0:1">

                    <div *ngIf="p.BookingOrTransactionItemType == 6">
                      <p style="font-size:14px; font-weight:600; margin:5px 0px;">
                        {{ "GymMembershipCode" | translate }} : {{ p.GymMembershipCode }} </p>
                      <p style="font-size:14px; font-weight:600; margin:0px;">
                        {{'IdNumber' | translate}} : {{item.client.CardNumber}}
                      </p>
                      <p style="font-size:14px; font-weight:600; margin:5px 0px;">
                        {{'TheGymMembershipType' | translate}} : <span
                          *ngFor="let TrProductService of item.TrProductServices | slice:0:1">
                          {{TrProductService.service?.Category?.NameAr}}
                        </span>
                      </p>

                      <h4 style=" font-size: 48px;  padding: 5px;margin: 0;"> اشتراك عن الفترة </h4>
                      <p style="font-family:initial; font-size: 48px; margin: 5px 0;font-weight: 700; color: #000;">
                        <b>{{'From' | translate}} </b> &nbsp;&nbsp; <span> {{p.GymMembershipStartDate |
                          date:'dd/MM/yyyy'}}</span>
                      </p>
                      <p style="font-family:initial; font-size: 48px; margin: 5px 0;font-weight: 700; color: #000;">
                        <b>{{'To' | translate}}</b> &nbsp;&nbsp; <span> {{p.GymMembershipEndDate |
                          date:'dd/MM/yyyy'}}</span>
                      </p>
                    </div>
                  </div>
                </td>

                <td style="text-align: end; padding:0px;">
                  <div *ngIf="AccountSetup?.ShowQRImageInBill == true">
                    <div style="text-align: end;" *ngIf="AccountSetup?.QRImagePath">
                      <img [src]="AccountSetup?.QRImagePath" style="width: 100px;">
                    </div>
                  </div>

                </td>
              </tr>
            </table>
          </div>
          <div>

            <div *ngIf="item.TrProductServices?.length > 0">
              <table border="1" style="width: 100%; border-collapse: collapse; text-align: start !important; margin: 15px 0;
                border: 2px solid #000;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
                <thead style="text-align: start;">
                  <tr style="text-align: start;background: #ebebeb;">
                    <th
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Name' |
                      translate }}</th>
                    <th
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">

                      {{ sharedService?.CurrentBranch?.EmployeeShowType == employeeShowType.Doctor ?
                      ('TheDoctor' | translate) :
                      ( (sharedService?.CurrentBranch?.EmployeeShowType == employeeShowType.Trainer
                      ? ('TheTrainer' | translate) :
                      ('TheEmployee'| translate)) ) }}


                    </th>
                    <th
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Quantity' |
                      translate }}</th>
                    <th
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Price' | translate
                      }}</th>

                    <th
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Total' | translate
                      }}</th>

                    <th *ngIf="AccountSetup?.AllowUseClientsQueueSystem && AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
                            AccountSetup?.ClientsQueueSystemType == 2"
                      style="font-family:initial; font-size: 48px;font-weight: 700; text-align: start !important; color: #000; padding:5px;">
                      {{ 'QueueNumber' | translate
                      }}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let TrProductService of item.TrProductServices; let i=index">
                  <tr style="text-align: start;">
                    <td style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">
                      {{TrProductService.service ?
                      (sharedService.Lang == 'ar' ? TrProductService.service.NameAr :
                      TrProductService.service.NameEn ) :
                      TrProductService.product ?
                      (sharedService.Lang == 'ar' ? TrProductService.product.NameAr :
                      TrProductService.product.NameEn ) :
                      TrProductService.Package ?
                      (sharedService.Lang == 'ar' ? TrProductService.Package.NameAr :
                      TrProductService.Package.NameEn ) :
                      TrProductService.ItemType == bookingOrTransactionItemType.GiftCard ?
                      (TrProductService.GiftCardGlobalName) :
                      ""}}
                      <div *ngIf="AccountSetup?.AllowUseRooms == true">
                        <span *ngFor="let room of TrProductService.ResourceManagements">
                          {{ room.ResourceName }} <br>
                          {{ 'From' | translate }}: {{room.StartTime | date:'shortTime' }} ,
                          {{ 'To' | translate }}: {{room.EndTime | date:'shortTime'}}
                        </span>
                      </div>
                    </td>
                    <td style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">

                      {{ TrProductService.Employee?.GlobalName ?? ('NotFound'|translate) }} {{
                      TrProductService?.SecondEmployee ? " - " +
                      TrProductService.SecondEmployee.GlobalName : "" }}
                    </td>
                    <td style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">
                      {{TrProductService.Quantity }}
                    </td>
                    <td style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">
                      {{TrProductService.UnitPrice.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>
                    <td style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">
                      {{TrProductService.Total.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>
                    <td *ngIf="AccountSetup?.AllowUseClientsQueueSystem && AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
                            AccountSetup?.ClientsQueueSystemType == 2"
                      style="font-family:initial; font-size: 48px;font-weight: 700;color: #000; padding:5px;">
                      {{TrProductService.ClientNumberInQueue }}
                    </td>
                  </tr>

                  <tr
                    *ngIf="TrProductService?.TransactionsProductOrServiceSessions && TrProductService?.TransactionsProductOrServiceSessions?.length > 0">
                    <td colspan='4'>
                      <table border="1" style="width: 100%;">
                        <tr
                          *ngFor="let sessionervice of TrProductService?.TransactionsProductOrServiceSessions; let i=index">
                          <!-- <td> {{'Sessions' | translate }} </td> -->
                          <td>
                            {{ sharedService.Lang == 'ar' ? sessionervice.ServiceSession?.NameAr :
                            sessionervice.Service?.NameEn || ''}}
                          </td>
                          <td style="color: #000; padding:5px;">
                            {{ sharedService.Lang == 'ar' ? sessionervice.Employee?.NameAr :
                            sessionervice.Employee?.NameEn || ''}}
                          </td>
                          <td> {{sessionervice.Total}}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr
                    *ngIf="TrProductService.TransactionsPackageServices && TrProductService.TransactionsPackageServices.length != 0">
                    <!-- <td> {{'Package' | translate}}</td> -->
                    <td colspan='4'>
                      <table border="1" style="width: 100%;">
                        <tr *ngFor="let packageservice of TrProductService.TransactionsPackageServices; let i=index">
                          <td>
                            <p style="font-size:13px;"> {{ sharedService.Lang == 'ar' ? packageservice.Service?.NameAr :
                              packageservice.Service?.NameEn}}</p>
                          </td>
                          <td style="color: #000; padding:5px;">
                            {{ sharedService.Lang == 'ar' ? packageservice.Employee?.NameAr :
                            packageservice.Employee?.NameEn}}
                          </td>
                          <td> {{packageservice.ServicePriceInPackage.toFixed(sharedService.GetFractionRoundCount)}}
                          </td>
                        </tr>
                      </table>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

            <table *ngIf="item.Comment">
              <tr>
                <td>
                  {{item.Comment}}
                </td>
              </tr>
            </table>

            <hr>
            <div>

              <table style="width: 100%; text-align: start; " [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
                <tr>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000;">
                    {{'TotalBeforeDiscounts' | translate }} :
                  </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span>{{ item.PriceBeforeDiscount.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{'ItemDiscounts' | translate }} :
                  </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ +(item.DiscountValue).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterDiscounts' | translate }} :
                  </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span>
                      {{ item.PriceAfterDiscount.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="item.UsedClientDownPayment > 0">
                  <td style="
                      font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                    ">
                    {{ "ClientDownPayment" | translate }} :
                  </td>
                  <td style=" font-family: initial;
                      font-size: 18px;
                      font-weight: 700;
                      color: #000;
                      text-align: end;">
                    <span> {{ item.UsedClientDownPayment.toFixed(sharedService.GetFractionRoundCount) }}</span>
                    <span style="margin-inline-start: 10px">
                      {{
                      printLang == "ar"
                      ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td *ngIf="sharedService.AllowUseSalesTaxRate == true"
                    style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; "> {{ 'TotalIncludeTax' |
                    translate
                    }} : </td>
                  <td *ngIf="sharedService.AllowUseSalesTaxRate == false"
                    style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; "> {{ 'TotalAllAfterDis' |
                    translate }} : </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span>{{ item.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}</span>
                  </td>
                </tr>

                <tr *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{ 'ValueAddedTax' | translate }} ( % {{AccountSetup.SalesTaxRate}} ) : </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ item.TaxValue.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="sharedService.AllowUsePayForPostpaidBillsWhenCreateBills &&
                sharedService.AllowUsePostpaidSystemAndClientBalances &&
                item.TotalPaidAmountForPostpaidBillsWithCashSale &&
                item.TotalPaidAmountForPostpaidBillsWithCashSale > 0">
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{ 'PaidForPostpaidBills' | translate }} : </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{
                      item.TotalPaidAmountForPostpaidBillsWithCashSale.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="sharedService.AllowUsePayForPostpaidBillsWhenCreateBills &&
                sharedService.AllowUsePostpaidSystemAndClientBalances &&
                item.TotalPaidAmountForPostpaidBillsWithCashSale &&
                item.TotalPaidAmountForPostpaidBillsWithCashSale > 0">
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterPaidForPostpaidBills' | translate }} : </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ (item.TotalNetPrice +
                      item.TotalPaidAmountForPostpaidBillsWithCashSale).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>


                <tr *ngIf="sharedService.AllowUsePostpaidSystemAndClientBalances &&
                           item.RemainingAmountFromPostpaid &&
                           item.RemainingAmountFromPostpaid > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'RemainingAmountFromPostpaid' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ item.RemainingAmountFromPostpaid.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>


                <tr *ngIf="sharedService.AllowUsePostpaidSystemAndClientBalances &&
                item.RemainingAmountFromPostpaid &&
                item.RemainingAmountFromPostpaid > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterRemainingAmountFromPostpaid' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ (item.TotalNetPrice -
                      item.RemainingAmountFromPostpaid).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>



                <tr *ngIf="sharedService.AllowUseClientsBalancesInSales &&
                          item.UseClientBalance &&
                          item.UsedClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'UsedClientBalanceInPayment' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ item.UsedClientBalance.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>


                <tr *ngIf="sharedService.AllowUseClientsBalancesInSales &&
                            item.UseClientBalance &&
                            item.UsedClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterUsedClientBalance' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ (item.TotalNetPrice -
                      item.UsedClientBalance).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>



                <tr *ngIf="sharedService.AllowUseClientsBalancesInSales &&
                item.RefundedClientBalance &&
                item.RefundedClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'UsedClientBalanceInPaymentRefund' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ item.RefundedClientBalance.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>


                <tr *ngIf="sharedService.AllowUseClientsBalancesInSales &&
                  item.RefundedClientBalance &&
                  item.RefundedClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterUsedClientBalanceRefund' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ (item.TotalNetPrice -
                      item.RefundedClientBalance).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>



                <tr *ngIf="sharedService.AllowUseAddRemainingAmountToClientBalance &&
                item.AddedRemainingAmountToClientBalance &&
                item.AddedRemainingAmountToClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'RemainingAmountAddedToClientBalance' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{
                      item.AddedRemainingAmountToClientBalance.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>


                <tr *ngIf="sharedService.AllowUseAddRemainingAmountToClientBalance &&
                item.AddedRemainingAmountToClientBalance &&
                item.AddedRemainingAmountToClientBalance > 0">
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; ">
                    {{ 'TotalAfterAddedRemainingAmountToClientBalance' | translate }} : </td>
                  <td style="font-family:initial;font-size: 18px;font-weight: 700; color: #000; text-align: end;">
                    <span> {{ (item.TotalNetPrice +
                      item.AddedRemainingAmountToClientBalance).toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="margin-inline-start: 10px;">
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; ">
                    {{'PaymentTypes' | translate }} : </td>
                  <td style="font-family:initial;font-size: 48px;font-weight: 700; color: #000; text-align: end;">
                    <span *ngFor="let p of item.TransactionsPayments">
                      {{p.PaymentMethod.NameAr}}

                      : {{p.Amount.toFixed(sharedService.GetFractionRoundCount)}}

                      <span style="margin-inline-start: 10px;">
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn}}</span>

                      <span *ngIf="p.PaymentMethod.PaymentMethodType == paymentMethodType.GiftCard">
                        - {{'CardCode' | translate }} : {{p.GiftCardCode}} -
                      </span>
                      <!-- <div *ngIf="item.WalletDiscountValue != 0">
                      {{ 'Wallet' | translate }} : {{item.WalletDiscountValue.toFixed(sharedService.GetFractionRoundCount)}} {{sharedService.Lang== 'ar' ?
                      sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}
                    </div> -->
                    </span><br>
                    <span *ngIf="item.UsedClientDownPayment != 0"> {{'DownPayment' | translate}} :
                      {{item.UsedClientDownPayment.toFixed(sharedService.GetFractionRoundCount)}}
                      <span style="margin-inline-start: 10px;">
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn}}</span>
                    </span>
                  </td>
                </tr>
              </table>

              <div *ngIf="item.TrProductServices?.length > 0">
                <div *ngFor="let TrProductService of item.TrProductServices; let i=index">
                  <table *ngIf="TrProductService.TransactionsProductOrServiceImages?.length !=0">
                    <tbody *ngFor="let images of TrProductService.TransactionsProductOrServiceImages">
                      <tr>
                        <td style="text-align:center;">
                          <img style="width: 80%; margin:0px auto;" src="{{images.ServiceImage?.ImagePath}}">
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li *ngFor="let notes of images.TransactionsProductOrServiceImageNotes"> {{notes.NoteIndex
                              +1}} - {{notes.NoteText}}</li>

                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="width: 100%; height: 1px; border:1px solid #000; margin: 15px 5px 15px;"></div>
            <p
              style="text-align: center; font-weight: 700;color: #000; margin: 10px 0px;font-family:initial; font-size: 48px;font-weight: 700;">
              {{ 'Phone' | translate }} : {{AccountSetup?.PhoneNumber}} - {{AccountSetup?.MobileNumber}}</p>

            <p *ngIf="AccountSetup?.AllowShowCommercialRecordNumberInPrint && AccountSetup?.CommercialRecordNumber && AccountSetup?.CommercialRecordNumber.length > 0"
              style="text-align: center; font-weight: 700;color: #000; margin: 10px 0px;font-family:initial; font-size: 48px;font-weight: 700;">
              {{ 'CommercialRecordNumber' | translate }}: {{ AccountSetup?.CommercialRecordNumber }}
            </p>
            <p *ngIf="AccountSetup?.AllowShowTaxRegistrationNumberInPrint && AccountSetup?.TaxRegistrationNumber && AccountSetup?.TaxRegistrationNumber.length > 0"
              style="text-align: center; font-weight: 700;color: #000; margin: 10px 0px;font-family:initial; font-size: 48px;font-weight: 700;">
              {{ 'TaxRegistrationNumber' | translate }}: {{ AccountSetup?.TaxRegistrationNumber }}
            </p>
            <p
              style="text-align: center; font-weight: 700;color: #000;margin: 10px 0px;font-family:initial; font-size: 48px;font-weight: 700;">
              {{ 'Address' | translate }} : {{AccountSetup?.Address}}</p>
            <div style="width: 100%; height: 1px; border:1px solid #000; margin: 15px 5px 15px;"></div>
            <p
              style="text-align: center; font-weight: 700;color: #000;font-family:initial; font-size: 48px;font-weight: 700;">
              {{AccountSetup?.PrintReceiptAdvertText}}
            </p>


            <span *ngIf="AccountSetup?.AllowUseClientsQueueSystem && AccountSetup?.AllowShowClientsQueueNumberInPrintBill &&
            AccountSetup?.ClientsQueueSystemType == 1 &&
            item.ClientNumberInQueue && item.ClientNumberInQueue > 0">

              <div style="width: 100%; height: 1px; border:1px solid #000; margin: 5px;"></div>
              <p
                style="text-align: center; font-weight: 700;color: #000;font-family:initial; font-size: 18px;font-weight: 700;">
                {{'ClientNumberInQueue' | translate}} : {{item?.ClientNumberInQueue}}
              </p>
            </span>

            <div style="text-align: center;" *ngIf="AccountSetup?.AllowGenerateQRCode ">
              <ngx-qrcode [width]="sharedService.GetQRCodeSize()" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                [value]="item.ZaqatQRCode" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>

              <hr>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
