<div [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'" style="width: 95%; margin: 15px auto;" *ngIf="item">

  <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
    <img [src]="AccountSetup?.ImagePath" style="width: 100px" />
  </div>
  <!-- <div style="text-align: center" >
    <img src="assets/img/g-logo.png" style="width: 100px" />
  </div> -->
  <h4 style="text-align: center;padding: 0px;margin:5px 0;">
    {{AccountSetup?.SalesInvoiceHeaderTextAR ?
    AccountSetup?.SalesInvoiceHeaderTextAR : ('SimpleTaxInvoice' | translate
    ) }}
  </h4>

  <h5 class="hed" *ngIf="item.BookingId"
    style="margin: 5px 0  0 0; padding: 0;color: #430fd4; text-align: center; font-weight: 600; font-size: 16px;">
    {{'ForBookingPayment' | translate }} ( {{'Number' | translate }}
    &nbsp;{{item.BookingId}})
  </h5>

  <!-- <h5 style="text-align: center;padding: 5px;background: #eee;"> النوع : أجل </h5> -->
  <div style="display: flex;justify-content: space-between;margin: 15px 15px;">
    <div>
      <table>
        <tr>
          <td style=" font-size: 14px; font-weight: 600; color: #000;  padding: 3px 0 0 3px;">
            {{'Date'| translate }} &nbsp; {{ item.AddedDate | date:'dd/MM/yy'}}
          </td>
          <td style="font-size: 14px; font-weight: 600; color: #000;  padding: 3px 0 0 3px;">
            {{'InvoiceNum'| translate }} &nbsp; {{item.TransactionCodeText}}
          </td>
        </tr>
        <tr>

          <td style="width:25%;">
            <p style="font-size: 14px; font-weight: 600; color: #000;  padding: 3px 0 0 3px;">
              {{'TheBranchName'| translate}} </p>
          </td>
          <td style="width:75%;">
            <p style="font-size: 14px; font-weight: 600; color: #000;  padding: 3px 0 0 3px;">
              {{AccountSetup?.Name}}
            </p>
          </td>
        </tr>
        <tr>

          <td style="width:25%;">
            <p style="font-size: 14px; font-weight: 600; color: #000; padding: 3px 0 0 3px;">
              {{'BranchAddress'| translate}}
            </p>
          </td>
          <td style="width:75%;">
            <p style="font-size: 14px; font-weight: 600; color: #000; padding: 3px 0 0 3px;">
              {{AccountSetup?.Address ?? AccountSetup?.WebsiteAddress}}
            </p>
          </td>


        </tr>
        <tr>
          <td style="width:25%;vertical-align: top; margin: 10px 0; padding: 10px 0;">
            <p style="font-size: 14px; font-weight: 600; color: #000;">
              {{'TaxRegistrationNumber'|translate}}</p>
          </td>
          <td style="width:75%;vertical-align: top; margin: 10px 0; padding: 10px 0;">
            <p style="font-size: 14px; font-weight: 600; color: #000;">
              {{AccountSetup?.CommercialRecordNumber}} - {{AccountSetup?.TaxRegistrationNumber}}
            </p>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top; margin: 10px 0; padding: 10px 0;">
            <p style="font-size: 14px; font-weight: 600; color: #000;">مقدمة الى</p>
          </td>
          <td>
            <p style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
              {{ item.client?.Name }}

              <span style="font-size: 14px; font-weight: 600; margin: 5px 0px"
                *ngIf="AccountSetup?.AllowShowClientNationalityInPrint">
                - {{ "ClientNationality" | translate }} : {{ item.client?.NationalityName ?? ( "NotDefined" |
                translate) }}
              </span>
              <span *ngIf="item.client?.Address" style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
                - {{ item.client?.Address }}
              </span>
              <span *ngIf="item.client?.CommercialRecordNumber"
                style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
                - {{ ("CommercialRecordNumber" | translate) + ": " + item.client?.CommercialRecordNumber }}
              </span>
              <span *ngIf="item.client?.TaxRegistrationNumber"
                style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
                - {{ ("TaxRegistrationNumber" | translate) + ": " + item.client?.TaxRegistrationNumber }}
              </span>
              <span *ngIf="item.client?.CommercialRecordNumberIssuingAuthorityName"
                style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
                - {{ ("CommercialRecordNumberIssuingAuthorityName" | translate) + ": " +
                item.client?.CommercialRecordNumberIssuingAuthorityName }}
              </span>
              <span *ngIf="item.client?.TaxRegistrationNumberIssuingAuthorityName"
                style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
                - {{ ("TaxRegistrationNumberIssuingAuthorityName" | translate) + ": " +
                item.client?.TaxRegistrationNumberIssuingAuthorityName }}
              </span>
            </p>

          </td>
        </tr>

        <!--  <tr>
          <td style="vertical-align: top; margin: 10px 0; padding: 10px 0;">
            <p style="font-size: 14px; font-weight: 600; color: #000;"> {{ 'TaxRegistrationNumber' | translate }}:
            </p>
          </td>
          <td>
            <p style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
              {{ item.client?.TaxRegistrationNumber }}</p>
          </td>
        </tr> -->
      </table>
    </div>
    <!--
     <h6 style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
        {{AccountSetup?.Name}}
      </h6>
      <p style="font-size: 14px; font-weight: 500; color: #000;margin: 10px 0;">
        {{AccountSetup?.Address}}
      </p>
      <p style="font-size: 14px; font-weight: 500; color: #000;margin: 10px 0;">
        {{AccountSetup?.AddressCulture}}
      </p>
      <h6 style="font-size: 14px; font-weight: 600; color: #000;margin: 10px 0;">
        {{AccountSetup?.WebsiteAddress}}
      </h6>
      <p style="font-size: 14px; font-weight: 500; color: #000;margin: 10px 0;">
        {{AccountSetup?.CommercialRecordNumber}} - {{AccountSetup?.TaxRegistrationNumber}}
      </p>
    </div>-->
  </div>
  <div>
    <table style="width: 100%;" border="2">
      <tr style="background-color: #fff2cc;">
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> # </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> {{ "Name" | translate }}
          </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> الكمية </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> السعر
            ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}}) </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> المجموع
            ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})
          </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> الخصم
            ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}}) </p>
        </th>
        <th>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
            <small> المجموع بعد الخصم ({{sharedService.Lang== 'ar' ?
              sharedService.CurrencyNameAr
              : sharedService.CurrencyNameEn}}) </small>
          </p>
        </th>
      </tr>
      <tr *ngFor="let TrProductService of item.TrProductServices; let i=index">
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">1</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
            {{TrProductService.service ?
            (sharedService.Lang == 'ar' ? TrProductService.service.NameAr :
            TrProductService.service.NameEn ) :
            TrProductService.product ?
            (sharedService.Lang == 'ar' ? TrProductService.product.NameAr :
            TrProductService.product.NameEn ) :
            TrProductService.Package ?
            (sharedService.Lang == 'ar' ? TrProductService.Package.NameAr :
            TrProductService.Package.NameEn ) :
            ""}}
          </p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 500; color: #000; padding:5px;margin: 0;">
            {{TrProductService.Quantity }}</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 500; color: #000; padding:5px;margin: 0;">
            {{TrProductService.UnitPrice.toFixed(sharedService.GetFractionRoundCount) }} ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 500; color: #000; padding:5px;margin: 0;">
            {{TrProductService.PriceBeforeDiscount.toFixed(sharedService.GetFractionRoundCount) }}
            ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 500; color: #000; padding:5px;margin: 0;">
            {{TrProductService.DiscountValue.toFixed(sharedService.GetFractionRoundCount) }} ({{sharedService.Lang==
            'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}}) </p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 500; color: #000; padding:5px;margin: 0;">
            {{TrProductService.PriceAfterDiscount.toFixed(sharedService.GetFractionRoundCount) }}
            ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})</p>
        </td>
      </tr>
      <tr style="background-color: #f2f2f2;">
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"></p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"> الاجمالى </p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"></p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;"></p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
            {{item.PriceBeforeDiscount.toFixed(sharedService.GetFractionRoundCount)}} ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
            {{item.DiscountValue.toFixed(sharedService.GetFractionRoundCount)}} ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}})</p>
        </td>
        <td>
          <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
            {{item.PriceAfterDiscount.toFixed(sharedService.GetFractionRoundCount)}} ({{sharedService.Lang== 'ar' ?
            sharedService.CurrencyNameAr
            : sharedService.CurrencyNameEn}}) </p>
        </td>
      </tr>
    </table>
    <br>

    <div style="width:100%;display: flex">
      <div style="width:60%">
        <p>
          {{item?.Comment}}
        </p>
      </div>
      <div style="width:40%">
        <table border="2">
          <tr>
            <td>
              <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
                {{ 'ValueAddedTax' | translate }} ( % {{(AccountSetup?.SalesTaxRate ?? 0
                ).toFixed(sharedService.GetFractionRoundCount)}} )
              </p>
            </td>
            <td>
              <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
                {{item.TaxValue.toFixed(sharedService.GetFractionRoundCount)}} ({{sharedService.Lang== 'ar' ?
                sharedService.CurrencyNameAr
                : sharedService.CurrencyNameEn}})</p>
            </td>
          </tr>
          <tr style="background-color: #fff2cc;">
            <td>
              <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">الصافي </p>
            </td>
            <td>
              <p style="font-size: 14px; font-weight: 600; color: #000; padding:5px;margin: 0;">
                {{item.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount)}} ({{sharedService.Lang== 'ar' ?
                sharedService.CurrencyNameAr
                : sharedService.CurrencyNameEn}})</p>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div>
      <div style="text-align: end; height:180px;" *ngIf="AccountSetup?.AllowGenerateQRCode ">
        <ngx-qrcode [width]="250" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
          [value]="item.ZaqatQRCode" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>
        <hr>

      </div>
    </div>
    <!--  <div style="margin-top:-180px;" *ngIf="AccountSetup?.Id == 17652 || AccountSetup?.Id == 19100 || AccountSetup?.Id ==  20286"> -->
    <div style="margin-top:-180px;">

      <h5 *ngIf="!ContractTermText"> 1. يتم التحويل على حساب الشركة طبقا للبيانات التالية:</h5>
      <div *ngIf="ContractTermText">
        <pre>
          <div [innerHTML]="ContractTermText">  </div>
        </pre>
      </div>

      <div *ngIf="!ContractTermText">


        <div *ngIf="AccountSetup?.Id == 17652">
          <ul>
            <li> اسم صاحب الحساب: مؤسسة المنطقة الذكية لتقنية المعلومات </li>
            <li> اسم البنك: البنك الأهلي التجاري </li>
            <li> رقم الآيبان: SA1110000026900000170601</li>
            <li> رقم الحساب: 26900000170601 </li>
          </ul>
        </div>

        <div *ngIf=" AccountSetup?.Id ==  20286">
          <ul>
            <li> اسم صاحب الحساب: مؤسسة ظافر عبدالله علي الشهري للتجارة</li>
            <li> اسم البنك: البنك الاهلي السعودي </li>
            <li> رقم الآيبان: SA1210000070500000171507</li>
            <li> رقم الحساب: 70500000171507 </li>
          </ul>
        </div>

        <div *ngIf="AccountSetup?.Id == 19100">
          <ul>
            <li> اسم صاحب الحساب: شركة جلاميرا العربية لتقنية المعلومات شركة شخص واحد </li>
            <li> اسم البنك: الراجحي </li>
            <li> رقم الحساب المصرفي الدولي (آيبان) : SA2280000487608016547640</li>
            <li> رقم الحساب: 487608016547640 </li>

          </ul>
        </div>
        <h5 style="margin:5px 0;"> 2. الصيانة والدعم الفني</h5>
        <ul>
          <li>
            المتابعة والدعم الفني ضمن الإطار المتفق عليه، ولا يوجد اية إضافات على المنتج وفي حالة رغبة العميل في
            الإضافات يتم تقدير الوقت والتكلفة.
          </li>
          <li>
            يجب الأخذ في الاعتبار وبناء على خبرتنا في التشغيل بأنه خلال الشهر الأول يحتاج العاملين المزيد من
            الدعم
            والتدريب ولذلك يجب تقبل ذلك من طرفكم والاخذ في الاعتبار الوقت المستغرق.
          </li>
          <li>
            يلزم تخصيص شخص من طرفكم لاستلام المزايا المقدمة والتوقيع على ذلك.
          </li>
        </ul>

        <h5 style="margin:5px 0;"> 3. الضمانات</h5>
        <ul>
          <li> التدريب خلال فترة التشغيل الأولي وتوفير كافة وسائل الدعم المستمر. </li>
          <li> نحن نضمن لك سرية بياناتك. </li>
          <li> يمكنكم التواصل مباشرة معنا من خلال الواتس آب، أو الايميل الرسمي
            <span> sales@glamera.com </span>
            <!-- <span *ngIf="AccountSetup?.Id == 17652 || AccountSetup?.Id ==  20286">  sales.ksa@smartzone-it.com </span> -->
          </li>

        </ul>
        <h5 style="margin:5px 0;"> 4. سياسة الإلغاء والاسترجاع </h5>
        <ul>
          <li>
            في حالة الرغبة في استرداد المبالغ المدفوعة بعد استلام قيمة الاشتراك السنوي وخلال فترة الشهر الأول
            يتم استرداد 50 % من المبلغ المستلم، أما بعد الشهر الأول يتم استرداد فقط 25 % من المبلغ المدفوع،
            وكلاهما مخصوما منها 15% من القيمة المستردة مقابل التدريب والدعم الفني، وبعد ذلك لا يحق استرداد أي
            مبالغ بأي شكل.
          </li>

          <li> بتحويل قيمة الفاتورة الى حسابنا البنكي فانه يعتبر موافقة وتعميد على ماورد فيها بالتفاصيل
          </li>
        </ul>
      </div>

    </div>
    <h6 style="font-size: 14px; font-weight: 600; color: #777; padding:5px;margin: 50px 0 30px 0;">

      <!-- {{AccountSetup?.PrintReceiptAdvertText}} -->

      <!-- هذه الفاتورة مصدرة الكترونيا من النظام الالكرتوني المحاسبي لمؤسسة المنطقة الذكية لتقنية المعلومات ،ويعتبر
            سند حقيقيا يعتد به في المطالبات
            بين الطرفين ولا يلزم التوقيع عليها، وطريقة استلامه الوحيدة عبر ايميل الشركة الرسمي المنتهي بالدومين التالى @
            smartzone-it.com -->

    </h6>
  </div>
</div>
