import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { Attachment } from '../../models/classes/transactions/attachment';
import { FileType } from '../../models/enums/file-type';
import { UploadResult } from '../../models/search-result/upload-result-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../../services/other/shared.service';
import { UploadService } from '../../services/upload/upload.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsComponent {
  @Input() public attachmentsFiles: Attachment[] = [];
  @Input() public vedioAttachement: any;
  @Input() public readonly title: string;
  @Input() public readonly previewOnly: boolean;
  @Input() public showVedio: boolean;
  @Input() public readonly vedioOnly: boolean;
  @Input() public folder: string;

  @Output() initialized: EventEmitter<AttachmentsComponent> =
    new EventEmitter<AttachmentsComponent>();

  public uploadImageLoading: boolean;
  public uploadPDFLoading: boolean;
  public uploadVedioLoading: boolean;

  public readonly FILE_TYPE = FileType;
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly adminService: AdminService,
    private readonly lightbox: Lightbox,
    private readonly lightboxConfig: LightboxConfig,
    private readonly cdr: ChangeDetectorRef,
    private readonly videoModal: MatDialog,
    private readonly uploadService: UploadService,
    private readonly sharedService: SharedService
  ) {}

  public get fileType(): typeof FileType {
    return FileType;
  }

  ngAfterViewInit(): void {
    this.initialized.emit(this);
  }

  public reset(): void {
    this.attachmentsFiles = [];
    this.cdr.detectChanges();
  }

  public update(
    values: {
      Id: number;
      FileName: string;
      FolderName: string;
      FileType: FileType;
      FilePath: string;
      BookingId: number;
      JournalId: number;
      TransactionId: number;
      FileNotes: string;
    }[]
  ): void {
    this.attachmentsFiles = [];
    values.forEach((attachment) => {
      if (attachment.FileType !== FileType.VIDEO) {
        var attachmment = new Attachment();

        attachmment.Id = attachment.Id;
        attachmment.FileName = attachment.FileName;
        attachmment.FolderName = attachment.FolderName;
        attachmment.FileType = attachment.FileType;
        attachmment.BookingId = attachment.BookingId;
        attachmment.TransactionId = attachment.TransactionId;
        attachmment.FilePath = attachment.FilePath;
        attachmment.FileNotes = attachment.FileNotes;
        attachmment.JournalId = attachment.JournalId;

        this.attachmentsFiles.push(attachmment);
      }
    });
    this.cdr.detectChanges();
  }

  public uploadAttachment(event: Event, type: FileType): void {
    const file = (event.target as HTMLInputElement).files[0];
    console.log(file);
    if (type === FileType.FILE) {
      this.uploadPDFLoading = true;
    } else if (type === FileType.IMAGE) {
      this.uploadImageLoading = true;
    } else {
      this.uploadVedioLoading = true;
    }
    this.uploadService
      .upload(file, this.folder)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (uploadResult: ExecutionResponse<UploadResult>) => {
          var result = uploadResult.Result;

          if (type === FileType.FILE) {
            this.attachmentsFiles.push({
              Id: 0,
              BookingId: null,
              TransactionId: null,
              JournalId: null,
              Journal: null,
              Booking: null,
              Transaction: null,
              SupplierId: null,
              ClientMedicalHistoryId: null,
              FileName: result[0].FileName,
              FolderName: result[0].FolderName,
              FilePath: 'assets/img/PDF.png',
              FileType: FileType.FILE,
              FileNotes: null,
            });
            this.uploadPDFLoading = false;
          } else if (type === FileType.IMAGE) {
            this.attachmentsFiles.push({
              Id: 0,
              BookingId: null,
              TransactionId: null,
              JournalId: null,
              Journal: null,
              Booking: null,
              Transaction: null,
              SupplierId: null,
              ClientMedicalHistoryId: null,
              FileName: result[0].FileName,
              FolderName: result[0].FolderName,
              FilePath:
                result[0]
                  .Path /*  `${this.sharedService.UploadUrl + result[0].FolderName}/${result[0].FileName}`, */,
              FileType: FileType.IMAGE,
              FileNotes: null,
            });
            this.uploadImageLoading = false;
          }
          this.cdr.detectChanges();
        },
      });
  }

  public removeAttachment(index: number): void {
    this.attachmentsFiles.splice(index, 1);
  }

  public openLightbox(index: number): void {
    const lightboxAlbum = [];
    this.attachmentsFiles.forEach((attachment) => {
      if (attachment.FileType === FileType.IMAGE) {
        lightboxAlbum.push({
          src: attachment.FilePath,
          caption: attachment.FileName,
          thumb: null,
        });
      }
    });

    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.showRotate = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.enableTransition = true;

    this.lightbox.open(lightboxAlbum, index);
  }

  removeVedioAttachment(): void {
    sessionStorage.removeItem('VEDIO_ID');
    this.vedioAttachement = {
      VideoPath: '',
      VideoPicturePath: '',
      FileType: '',
    };
  }
}
