import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ResponseState } from 'src/app/models/support/response-state';
import { UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaymentMethodType } from 'src/app/models/enums/payment-method-type';
import { CreditCardSearchCriteria } from 'src/app/models/search-criterias/credit-card-search-criteria';
import { PaymentMethodSearchCriteria } from 'src/app/models/search-criterias/payment-method-search-criteria';
import { AppointmentService } from 'src/app/services/appointment/appointment.service';
import { CreditCardService } from 'src/app/services/cashier/credit-card.service';
import { SharedService } from 'src/app/services/other/shared.service';
import { AdminService } from 'src/app/dashboard/admin/service/admin.service';
import { AccountPayments } from 'src/app/dashboard/appointment/model/cashier-constants';
import { PaymentMethodService } from 'src/app/services/cashier/payment-method.service';
import { GiftCardInformationService } from 'src/app/services/gift-card/gift.card.information.service';
import { CheckGiftCardCodeSearchCriteria } from 'src/app/models/search-criterias/checkGiftCardCodeSearchCriteria';
import { CheckGiftCardCodeSearchResult } from 'src/app/models/search-result/checkGiftCardCodeSearchResult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalClientGiftCardDetailsComponent } from 'src/app/shared/modal-client-gift-card-details/modal-client-gift-card-details.component';
import { Company } from 'src/app/models/classes/company/company';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod } from 'src/app/models/classes/cashier/payment-method';
import { SendOTPCodeToClientSearchResult } from 'src/app/models/search-result/send-OTP-code-to-client-search-result';
import { SendOTPCodeToClientSearchCriteria } from 'src/app/models/search-criterias/send-OTP-code-to-client-search-criteria';
import { ResourceStatus } from 'src/app/models/enums/resource-status';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';
import { PaymentScreenType } from 'src/app/models/enums/payment-screen-type';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {

  public TrPaForm: UntypedFormGroup;
  public PaymentMethodsList: PaymentMethod[];
  @Input() PaymentScreenType: PaymentScreenType = PaymentScreenType.Appointment;

  @Input()
  set TPForm(tpFormValue) {

    this.TrPaForm = tpFormValue;
    this.ValidateNotShowGiftCardWhenBuyGiftCard();

  }

  @Input()
  set PaymentMethods(paymentMethods) {

    this.PaymentMethodsList = paymentMethods;
    this.ValidateNotShowGiftCardWhenBuyGiftCard();

  }

  public CurrentGFormMaster: string = 'BookingOrTransactionModel';
  public CurrentTotalPriceName: string = 'BookingOrTransactionTotalPrice';

  @Input() ClientLoyalityAmount: any;
  @Input() UseLoyalityPoints: any;
  public accountPayments: any;
  public ErrorText: string;
  public LoadingCheckGiftCardCode: boolean = false;
  public LoadingSendOTPCodeToClient: boolean = false;
  public PaidAmount: number;
  public RemainingAmount: number;
  public PaymentMethodsListWithoutLoyalty: PaymentMethod[];
  VisaTypeName = ['HSBC', 'ALEXBANK ', 'QNB Viza '];

  @Output() myData = new EventEmitter();
  paymentMethodSearchCriteria: PaymentMethodSearchCriteria;
  creditCardSearchCriteria: CreditCardSearchCriteria;
  LoyaltyDiscount: any;
  Company: Company;

  constructor(
    public appointmentService: AppointmentService,
    public sharedService: SharedService,
    private adminService: AdminService,
    private paymentMethodService: PaymentMethodService,
    private creditCardService: CreditCardService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private giftCardInformationService: GiftCardInformationService
  ) {
    this.accountPayments = AccountPayments;
    this.paymentMethodSearchCriteria = new PaymentMethodSearchCriteria();
    this.creditCardSearchCriteria = new CreditCardSearchCriteria();
  }

  @Output() CalculateNew = new EventEmitter();

  cashsaleId;

  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }
  public get paymentScreenType(): typeof PaymentScreenType {
    return PaymentScreenType;
  }
  public get responseState(): typeof ResponseState {
    return ResponseState;
  }

  ValidateNotShowGiftCardWhenBuyGiftCard() {

    if (this.PaymentScreenType == PaymentScreenType.Appointment) {

      let bookingOrTransactionItems: any[] = this.TrPaForm.get(this.CurrentGFormMaster).get(
        'BookingOrTransactionItems').value;

      if (bookingOrTransactionItems && bookingOrTransactionItems.length > 0 &&
        this.PaymentMethodsList && this.PaymentMethodsList.length > 0) {

        var check = bookingOrTransactionItems.
          some(item => item.BookingOrTransactionItemType == BookingOrTransactionItemType.GiftCard) &&
          this.PaymentMethodsList.
            some(item => item.PaymentMethodType == PaymentMethodType.GiftCard);

        if (check) {
          this.PaymentMethodsList = this.PaymentMethodsList.
            filter(pm => pm.PaymentMethodType != PaymentMethodType.GiftCard);
        }

      }
    }
  }

  ngOnInit(): void {

    this.CurrentGFormMaster = this.PaymentScreenType == PaymentScreenType.CashRefund ?
      'Transaction' : 'BookingOrTransactionModel';

    this.CurrentTotalPriceName = this.PaymentScreenType == PaymentScreenType.CashRefund ?
      'TotalNetPrice' : 'BookingOrTransactionTotalPrice';

    this.cashsaleId = this.route.snapshot.params['id'];
    this.getByIdAccountSetup();
    this.getAccountCurrencies();

    if (!this.PaymentMethodsList || this.PaymentMethodsList.length <= 0) {
      this.searchPaymentMethodsListLite();
    } else {
      if (!this.cashsaleId) {
        const CashId = this.PaymentMethodsList.find((e) => e.IsDefault);
        const controlArray = <UntypedFormArray>(
          this.TrPaForm.get(this.CurrentGFormMaster).get(
            'TransactionsPayments'
          )
        );

        controlArray.controls[0]
          .get('PaymentMethodId')
          .patchValue(CashId?.Id);
        controlArray.controls[0].get('PaymentMethodType').patchValue(1);
      }

      this.PaymentMethodsListWithoutLoyalty = this.PaymentMethodsList.filter(p => p.PaymentMethodType != PaymentMethodType.Loyalty);
    }

    this.searchCreditCards();
    this.Company = this.sharedService.CurrentCompany;


  }

  GetAmountValue(number): number {
    return 0;
  }
  ViewError() {
    this.toastr.error(this.ErrorText);
  }
  openModalClientGiftCardDetailsComponent(GiftCardCode: string) {
    const modalRef = this.ngbModal.open(ModalClientGiftCardDetailsComponent, {
      windowClass: 'myCustomModalClasMinWidth85',
    });

    modalRef.componentInstance.GiftCardCode = GiftCardCode;
  }

  CheckIsReferenceNumberMandatory(paymentMethodId: number): boolean {

    var getAndCheck = this.PaymentMethodsList.find(pm => pm.Id == paymentMethodId && pm.IsReferenceNumberMandatory);
    if (getAndCheck)
      return true;
    else
      return false;
  }

  PaidAmountChanged(cashAmount: number) {

    if (this.PaidAmount && cashAmount && this.PaidAmount > cashAmount) {
      this.RemainingAmount = (this.PaidAmount - cashAmount);
    } else {
      this.RemainingAmount = null;
    }
  }

  SendOTPCodeToClientClicked(index: number) {
    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );

    var GiftCardCode: string =
      controlArray.controls[index].get('GiftCardCode').value;
    var GiftCardAmount: number =
      controlArray.controls[index].get('Amount').value;
    var GiftCardState: ResponseState =
      controlArray.controls[index].get('GiftCardState').value;

    if (
      !GiftCardAmount ||
      GiftCardAmount <= 0 ||
      (GiftCardState != this.responseState.Success &&
        !this.LoadingCheckGiftCardCode)
    ) {
      var message = this.translateService.instant(
        'GiftCardCodeAndAmountMustBeValidFirstBeforeSendOTPToClient'
      );

      this.toaster.warning(message, this.translateService.instant('OTPInfo'), {
        timeOut: 10000,
        extendedTimeOut: 10000,
        progressBar: true,
        tapToDismiss: false,
      });

      return false;
    }

    if (GiftCardCode && GiftCardCode.length > 0) {
      var criteria = new SendOTPCodeToClientSearchCriteria();
      criteria.GiftCardCode = GiftCardCode;
      criteria.GiftCardAmount = GiftCardAmount;

      this.LoadingSendOTPCodeToClient = true;
      this.giftCardInformationService
        .SendOTPCodeToClient(criteria)
        .subscribe((response: SendOTPCodeToClientSearchResult) => {
          if (response.State == ResponseState.Success) {
            var message = this.translateService.instant(
              'DoneSendOTPCodeToClientSuccessfully'
            );
            this.toaster.success(message);
            controlArray.controls[index]
              .get('GiftCardInformationOTPId')
              .patchValue(response.GiftCardInformationOTPId);
          }

          this.LoadingSendOTPCodeToClient = false;
        });
    } else {
      controlArray.controls[index]
        .get('GiftCardInformationOTPId')
        .patchValue(null);
    }
  }

  GiftCardCodeChanged(event, index) {

    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );

    var giftCardCode = controlArray.controls[index].get('GiftCardCode').value;

    controlArray.controls[index]
      .get('GiftCardBalanceChecked')
      .patchValue(false);

    if (giftCardCode && giftCardCode.length > 0) {
      this.LoadingCheckGiftCardCode = true;

      var criteria = new CheckGiftCardCodeSearchCriteria();
      criteria.GiftCardCode = giftCardCode;

      this.giftCardInformationService
        .CheckGiftCardCode(criteria)
        .subscribe((response: CheckGiftCardCodeSearchResult) => {
          controlArray.controls[index]
            .get('GiftCardState')
            .patchValue(response.State);

          controlArray.controls[index]
            .get('GiftCardBalanceChecked')
            .patchValue(true);

          controlArray.controls[index]
            .get('IsNewWithClient')
            .patchValue(response.IsNewWithClient);

          this.LoadingCheckGiftCardCode = false;

          if (response.State == ResponseState.Success) {
            controlArray.controls[index]
              .get('GiftCardBalance')
              .patchValue(response.GiftCardBalance);
          } else {
            this.ErrorText = response.Message;
          }
        });
    } else {

      controlArray.controls[index].get('GiftCardState').patchValue(null);

      controlArray.controls[index].get('GiftCardBalance').patchValue(null);
    }
  }

  /*======== getByIdAccountSetup=========================================================*/
  AccountSetup;
  AccountCurrencies;
  getByIdAccountSetup() {
    this.appointmentService
      .GetByIdAccountSetup(this.sharedService.AccountSetupId)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.AccountSetup = response.Result;
        }
      });
  }

  getAccountCurrencies() {
    let jsonObj = {
      AccountSetupId: this.sharedService.AccountSetupId,
      FreeText: '',
      Status: '',
    };

    this.adminService.GetAccountCurrency(jsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.AccountCurrencies = response.AccountCurrencys;

        if (!this.cashsaleId) {
          const controlArray = <UntypedFormArray>(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              'TransactionsPayments'
            )
          );
          controlArray.controls[0]
            .get('CurrencyId')
            .patchValue(this.AccountCurrencies[0].Id);
        }
      }
    });
  }


  searchPaymentMethodsListLite() {
    this.paymentMethodSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    this.paymentMethodSearchCriteria.UseLoyalityPoints = this.UseLoyalityPoints;
    this.paymentMethodSearchCriteria.Status = ResourceStatus.Active;

    this.paymentMethodService
      .searchPaymentMethodsLite(this.paymentMethodSearchCriteria)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {
          this.PaymentMethodsList = result.PaymentMethods;
          if (!this.cashsaleId) {
            const CashId = this.PaymentMethodsList.find((e) => e.IsDefault);
            const controlArray = <UntypedFormArray>(
              this.TrPaForm.get(this.CurrentGFormMaster).get(
                'TransactionsPayments'
              )
            );

            controlArray.controls[0]
              .get('PaymentMethodId')
              .patchValue(CashId?.Id);
            controlArray.controls[0].get('PaymentMethodType').patchValue(1);

            this.PaymentMethodsListWithoutLoyalty = this.PaymentMethodsList.filter(p => p.PaymentMethodType != PaymentMethodType.Loyalty);
          }
          this.ValidateNotShowGiftCardWhenBuyGiftCard();
        }
      });
  }

  CreditCards;
  searchCreditCards() {
    this.creditCardSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    this.creditCardService
      .searchCreditCards(this.creditCardSearchCriteria)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {
          this.CreditCards = result.CreditCards;
        }
      });
  }

  ChoosePayment(e, i) {
    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );
    controlArray.controls[i]
      .get('PaymentMethodType')
      .patchValue(e.PaymentMethodType);
    controlArray.controls[i]
      .get('CurrencyId')
      .patchValue(this.AccountCurrencies[0].Id);
    console.log(controlArray.controls[i].get('Amount').value);

    if (this.PaymentScreenType == PaymentScreenType.Appointment) {

      if (e.PaymentMethodType != PaymentMethodType.GiftCard) {
        controlArray.controls[i].get('GiftCardCode').patchValue(null);
        controlArray.controls[i].get('GiftCardInformationId').patchValue(null);
        controlArray.controls[i].get('GiftCardCode').clearValidators();
        controlArray.controls[i].get('GiftCardCode').updateValueAndValidity();

        controlArray.controls[i].get('GiftCardBalanceChecked').patchValue(null);
        controlArray.controls[i].get('GiftCardState').patchValue(null);

        if (this.Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards) {
          controlArray.controls[i].get('GiftCardOTPCode').patchValue(null);
          controlArray.controls[i]
            .get('GiftCardInformationOTPId')
            .patchValue(null);
          controlArray.controls[i].get('GiftCardOTPCode').clearValidators();
          controlArray.controls[i]
            .get('GiftCardOTPCode')
            .updateValueAndValidity();
        }
      } else {
        controlArray.controls[i]
          .get('GiftCardCode')
          .setValidators(Validators.required);
        controlArray.controls[i].get('GiftCardCode').updateValueAndValidity();

        if (this.Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards) {
          controlArray.controls[i]
            .get('GiftCardOTPCode')
            .setValidators(Validators.required);
          controlArray.controls[i]
            .get('GiftCardOTPCode')
            .updateValueAndValidity();
        }
      }
    }

    if (
      e.PaymentMethodType != PaymentMethodType.Other &&
      e.PaymentMethodType != PaymentMethodType.CreditCard
    ) {
      controlArray.controls[i].get('ReferenceCode').patchValue(null);
    }

    if (i == 0 && e.PaymentMethodType == PaymentMethodType.Loyalty) {
      if (
        this.ClientLoyalityAmount <
        Number(controlArray.controls[i].get('Amount').value)
      ) {
        this.sharedService.ToastrError(
          'لا يمكن استخدام المحفظة طريقة دفع أولى  '
        );

        const CashId = this.PaymentMethodsList.find(
          (e) => e.PaymentMethodType == PaymentMethodType.Cash
        );
        const controlArray = <UntypedFormArray>(
          this.TrPaForm.get(this.CurrentGFormMaster).get(
            'TransactionsPayments'
          )
        );
        controlArray.controls[0].get('PaymentMethodId').patchValue(CashId?.Id);
        controlArray.controls[0]
          .get('PaymentMethodType')
          .patchValue(PaymentMethodType.Cash);
      }
    }

    if (controlArray.controls.length > 1) {
      if (
        (i == 1 &&
          e.Id == controlArray.controls[0].get('PaymentMethodId').value) ||
        (i == 0 &&
          e.Id == controlArray.controls[1].get('PaymentMethodId').value)
      ) {
        this.sharedService.ToastrError('لا يسمح اختيار طريقة دفع مرتين');
        controlArray.controls[i].get('PaymentMethodId').patchValue(0);
      }
    }
  }

  GetTotalBeforeDiscountsAndTax(): number {

    var totalBeforeDiscountsAndTax = Number(
      this.TrPaForm.get(this.CurrentGFormMaster).get(
        'TotalBeforeDiscountsAndTax'
      ).value);

    return totalBeforeDiscountsAndTax;
  }

  LoyaltyOnAllAmountChanged(event, index: number) {

    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );
    let totalBeforeDiscountsAndTax = 0;

    if (event.target.checked) {

      totalBeforeDiscountsAndTax = Number(
        this.TrPaForm.get(this.CurrentGFormMaster).get(
          'TotalBeforeDiscountsAndTax'
        ).value);

      controlArray.controls[index]
        .get('Amount')
        .patchValue(totalBeforeDiscountsAndTax);

      controlArray.controls[index]
        .get('TempAmount')
        .patchValue(totalBeforeDiscountsAndTax.toFixed(this.sharedService.GetFractionRoundCount));

    } else {

      controlArray.controls[index]
        .get('Amount')
        .patchValue((0).toFixed(this.sharedService.GetFractionRoundCount));

      controlArray.controls[index]
        .get('TempAmount')
        .patchValue(null);

    }

    this.CalcSecondPayValue(null, index, totalBeforeDiscountsAndTax);
  }

  oneAmount = 0;
  twoAmount = 0;
  CalcSecondPayValue(e, i, totalBeforeDiscountsAndTax) {

    let value = e?.target?.value ? +e.target.value : +totalBeforeDiscountsAndTax;

    let transactionTotalPaidAmountForPostpaidBillsWithCashSale =
      this.TrPaForm.get(this.CurrentGFormMaster).get(
        'TransactionTotalPaidAmountForPostpaidBillsWithCashSale'
      ).value &&
        this.TrPaForm.get(this.CurrentGFormMaster).get(
          'TransactionTotalPaidAmountForPostpaidBillsWithCashSale'
        ).value > 0
        ? this.TrPaForm.get(this.CurrentGFormMaster).get(
          'TransactionTotalPaidAmountForPostpaidBillsWithCashSale'
        ).value
        : 0;

    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );

    if (i == 1) {
      // TotalBeforeDiscountsAndTax
      if (
        (value >
          Number(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              this.CurrentTotalPriceName
            ).value
          ) &&
          controlArray.controls[1].get('PaymentMethodType').value !=
          PaymentMethodType.Loyalty) ||
        (value >
          Number(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              'TotalAfterDiscounts'
            ).value
          ) +
          Number(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              'WalletDiscountValue'
            ).value
          ) &&
          controlArray.controls[1].get('PaymentMethodType').value ==
          PaymentMethodType.Loyalty)
      ) {
        this.sharedService.ToastrError('يجب إدخال قيمة أقل من أو تساوي الاجمالي قبل الضريبة ');

        let totalValueAfterPostpaid =
          transactionTotalPaidAmountForPostpaidBillsWithCashSale +
          Number(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              this.CurrentTotalPriceName
            ).value
          );

        controlArray.controls[0]
          .get('Amount')
          .patchValue(totalValueAfterPostpaid);

        controlArray.controls[1]
          .get('Amount')
          .patchValue(
            this.TrPaForm.get(this.CurrentGFormMaster).get(
              'WalletDiscountValue'
            ).value
          );
      } else {
        if (
          controlArray.controls[1].get('PaymentMethodType').value ==
          PaymentMethodType.Loyalty
        ) {
          this.TrPaForm.get(this.CurrentGFormMaster)
            .get('WalletDiscountValue')
            .setValue((value));
          this.CalculateNew.emit('Calc');
        } else {
          let totalValueAfterPostpaid =
            transactionTotalPaidAmountForPostpaidBillsWithCashSale +
            Number(
              this.TrPaForm.get(this.CurrentGFormMaster).get(
                this.CurrentTotalPriceName
              ).value
            );

          controlArray.controls[0]
            .get('Amount')
            .patchValue(
              (
                totalValueAfterPostpaid -
                controlArray.controls[1].get('Amount').value
              )
            );
        }
      }
    }
  }


  removeItem(i) {
    (
      this.TrPaForm.get(this.CurrentGFormMaster).get(
        'TransactionsPayments'
      ) as UntypedFormArray
    ).removeAt(i);

    this.TrPaForm.get(this.CurrentGFormMaster)
      .get('WalletDiscountValue')
      .patchValue(0);

    if (this.PaymentScreenType == PaymentScreenType.Appointment) {
      const controlArray1 = <UntypedFormArray>(
        this.TrPaForm.get(this.CurrentGFormMaster).get(
          'BookingOrTransactionItems'
        )
      );
      controlArray1.controls[0].get('WalletDiscountValue').patchValue(0);
    }

    const controlArray = <UntypedFormArray>(
      this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionsPayments')
    );
    // console.log('removeItem', this.TrPaForm.get(this.CurrentGFormMaster).get(this.CurrentTotalPriceName).value, this.TrPaForm.get(this.CurrentGFormMaster).get('TransactionTotalPaidAmountForPostpaidBillsWithCashSale').value);

    controlArray.controls[0]
      .get('Amount')
      .patchValue(
        Number(
          this.TrPaForm.get(this.CurrentGFormMaster).get(
            this.CurrentTotalPriceName
          ).value
        ) +
        this.TrPaForm.get(this.CurrentGFormMaster).get(
          'TransactionTotalPaidAmountForPostpaidBillsWithCashSale'
        ).value
      );

    let tp = this.TrPaForm.get(this.CurrentGFormMaster).get(
      'TransactionsPayments'
    ).value.length;
    for (let p = 0; p < tp; p++) { }

    this.CalculateNew.emit('Calc');
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
