<div id="printClientProfile">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-list"></i> &nbsp; {{'PackageGroups' | translate}} &nbsp;
      ( {{PackageName}} ) &nbsp; <span style="font-weight: 900;" class="text-info">
        ( {{'ChooseAllowedServicesToContinue' | translate}} )</span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-lg-12" *ngIf="!LoadingGetPackageGroups && PackageGroups">
        <div class="text-start py-4">

          <div class="row" style="margin-top: -3% !important;">
            <div class="col-lg-12">
              <div class="btnsTab">
                <button *ngFor="let packageGroup of PackageGroups;let packageGroupIndex=index" type="button" class="btn"
                  [class.selectedTab]="selectedTab === packageGroupIndex" (click)="tabClick(packageGroupIndex)">
                  {{ packageGroup.Name }}
                </button>
              </div>
            </div>
          </div>

          <div class="cardTabs">


            <div *ngFor="let packageGroup of PackageGroups;let packageGroupIndex=index"
              [hidden]="selectedTab != packageGroupIndex" class="tabsContent">
              <div class="row">

                <div class="col-lg-12" style="margin-top: 1%;">
                </div>

                <div class="col-lg-12" style="margin-top: 1%;">
                  <div class="formCard">
                    <table class="table table-sm">
                      <tr>

                        <td class="infoClass formatHeader">
                          {{ 'AllowedNumberOfServices' | translate }}: &nbsp;&nbsp;{{
                          packageGroup.AllowedNumberOfServices }} &nbsp;
                          {{ 'AService' | translate }}</td>

                        <td class="infoClass formatHeader">
                          {{ 'AllowServicesDuplication' | translate }}: &nbsp;&nbsp;{{
                          packageGroup.AllowServicesDuplication ?
                          ('Yes' | translate) : ('No' | translate) }} </td>

                        <td class="warningClass formatHeader" *ngIf="packageGroup.DoneChooseAllAllowedNumberOfServices">
                          {{ 'DoneChooseAllAllowedServicesForCurrentGroup' | translate }} </td>

                      </tr>
                    </table>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="packageGroup.PackageGroupServices; let PackageGroupServices;">
                  <div class="table-card">
                    <table class="table table-stripedd table-sm table-bordered">
                      <thead class="table-head">
                        <tr style="color: black; font-size: large;">

                          <th style="width: 40%;" class="formatTd text-center">{{ 'ServiceName' | translate }}</th>
                          <th style="width: 30%;" class="formatTd text-center">{{ 'ChoosenQuantity' | translate }}
                          </th>
                          <th style="width: 30%;" class="formatTd text-center">{{ 'Actions' | translate }}
                          </th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr *ngFor="let packageGroupService of PackageGroupServices;
                          let packageGroupServiceIndex = index" [ngClass]="{'back-green-1': packageGroupService.ChoosenQuantity == 1
                          , 'back-green-2': packageGroupService.ChoosenQuantity == 2
                          , 'back-green-3': packageGroupService.ChoosenQuantity > 2}">

                          <td style="width: 40%;" class="font-class text-center">
                            <span class="font-class overme20" title="{{ packageGroupService.Name}}">
                              {{ packageGroupService.Name }}
                            </span>
                          </td>

                          <td style="width: 30%;" class="text-center">
                            <span class="font-class overme20" title="{{ packageGroupService.ChoosenQuantity}}">
                              {{ packageGroupService.ChoosenQuantity > 0 ?
                              packageGroupService.ChoosenQuantity : ('NotFound' | translate) }}
                            </span>
                          </td>

                          <td style="width: 30%;" class="text-center">
                            <button class="btn btn-success btn-sm m-0 font-class" *ngIf="!packageGroup.DoneChooseAllAllowedNumberOfServices &&
                              CheckIfCanAdd(packageGroupIndex,packageGroupServiceIndex)"
                              (click)="AddQuantity(packageGroupIndex,packageGroupServiceIndex)"
                              title="{{ ('AddQuantity' |translate)}}">
                              <i class="fas fa-plus font-class"></i>
                            </button>
                            &nbsp;
                            <button *ngIf="packageGroupService.ChoosenQuantity > 0" class="btn btn-success btn-sm m-0"
                              (click)="RemoveQuantity(packageGroupIndex,packageGroupServiceIndex) "
                              title="{{ ('RemoveQuantity' |translate)}}">
                              <i class="fas fa-minus font-class"></i>
                            </button>
                          </td>

                        </tr>


                      </tbody>
                    </table>
                    <div class="text-center py-4 my-4" *ngIf="packageGroup.PackageGroupServices.length == 0">
                      <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>



        </div>
      </div>

      <div class="col-lg-12" *ngIf="LoadingGetPackageGroups">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" [disabled]="!CheckIfAllGroupsDoneChooseAllAllowedNumberOfServices() ||
      LoadingGetPackageGroups" data-dismiss="modal" (click)="SaveAndContinue()">

      <span style="font-weight: 900;" *ngIf="CheckIfAllGroupsDoneChooseAllAllowedNumberOfServices()">{{'SaveAndContinue'
        | translate}}</span>
      <span style="font-weight: 900;"
        *ngIf="!CheckIfAllGroupsDoneChooseAllAllowedNumberOfServices()">{{'ChooseAllowedServicesToContinue' |
        translate}}</span>

    </button>

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
