<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-cloud-upload-alt"></i> &nbsp; {{'UploadAttachements' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <app-attachments #attachmentsComponent (initialized)="
            onAttachmentsComponentInitialized($event)
          " [attachmentsFiles]="[]" [showVedio]="true" [vedioOnly]="false" [title]="'UploadPhotoOrPDF'"
          [folder]="'BookingOrTransactionAttachment'">
        </app-attachments>
      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{'save' | translate}}</button>

    <!-- <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button> -->
  </div>
</div>
