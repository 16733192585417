import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import * as io from 'socket.io-client';
import { SharedService } from '../services/other/shared.service';

@Injectable({
  providedIn: 'root'
})
export class GbSocketService {

  socket: any;
  onResult: Subject<any>;
  constructor(_sharedService: SharedService) {

    if (_sharedService.AllowUseGym) {
      // this.socket = io('http://localhost:9001', { reconnection: false });
      this.onResult = new Subject();
    }


  }


  connect() {

    // this.booking = io.connect('ws://85.93.91.32:7000/booking', {transports:['websocket']});
    // this.booking = io.connect('ws://localhost:5000/booking', {transports:['websocket']});
    // this.booking = io.connect('ws://192.168.1.100:5000/booking', {transports:['websocket']});
    this.socket.on('connect', () => {

      console.log('connected to socket server');
      //booking.emit('new', {hi:'hello'});
      this.socket.on('result', (result) => {


        this.onResult.next(result);

      });
    });

    //this.socket.connect();

  }


  read() {

    this.socket.emit('read', {});
  }

}
