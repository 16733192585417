import { AccountGroup } from "../../const/account-group";
import { CashierMainData } from "../cashier/cashier-main-data";
import { Client } from "../client/client";
import { BaseClass } from "../other/base-class";
import { Supplier } from "../supplier/supplier";
import { CostCenters } from "./costcenters";

export class AccountInfo extends BaseClass {

  Id: number;
  AccountNo: string;
  NameAr: string;
  NameEn: string;
  DescriptionAr: string;
  DescriptionEn: string;
  LevelNumber: number;
  ParentId?: number;
  AccountSetupId: number;
  IsChild: boolean;
  SupplierId : number;
  EmployeeId : number;
  ClientId :Number;
  CashierId:Number;
  ClientGroupId :Number;
  IsActive: boolean;
  IsDebit: boolean;
  ApplyTax: boolean;
  TaxRate: Number;
  AccountTypeId: number;
  AccountGroupId: number;
  AccountInfos: AccountInfo[];
  ParentNumber: string;
  Code: number;
  Client: Client;
  Supplier: Supplier;
  Cashier :CashierMainData;
  AccountGroup: AccountGroup;
  CostCenters : CostCenters[];
}
